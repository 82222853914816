import { Action, Middleware } from '@reduxjs/toolkit'

// Configure cross-tab sync
const actionTypes = [
	'machines/createMachine',
	'machines/editMachine',
	'machines/deleteMachine',
	'machines/reorderMachine',
	//
	'planning/createBooking',
	'planning/editBooking',
	'planning/deleteBooking',
	'planning/startBooking',
	'planning/completeBookingSetup',
	'planning/startBookingProduction',
	'planning/updateProgress',
	'planning/startBookingTeardown',
	'planning/completeBooking',
	'planning/restartBooking',
	'planning/createCalendarAdjustment',
	'planning/deleteCalendarAdjustment',
	'planning/setZoomLevel',
	'planning/setSchedulingDirection',
	'planning/ignoreAlert',
	'planning/activateAlert',
	'planning/pruneIgnoredAlerts',
	'planning/validatePlan/fulfilled',
	'planning/resetProgress/fulfilled',
	//
	'orders/createOrder',
	'orders/editOrder',
	'orders/deleteOrder',
	'orders/advanceOrderToPlanned',
	'orders/advanceOrderToInactive',
	'orders/advanceOrderToInProgress',
	'orders/advanceOrderToCompleted',
	'orders/revertOrderToPlanned',
	'orders/revertOrderToPending',
	//
	'products/createProduct',
	'products/editProduct',
	'products/deleteProduct',
	'products/reorderProduct',
	//
	'productOperations/createProductOperation',
	'productOperations/editProductOperation',
	'productOperations/deleteProductOperation',
	'productOperations/updateProductOperations',
	'productOperations/reorderProductOperation',
	//
	'tools/createTool',
	'tools/editTool',
	'tools/deleteTool',
	//
	'staffGroups/createStaffGroup',
	'staffGroups/editStaffGroup',
	'staffGroups/deleteStaffGroup',
	//
	'customers/createCustomer',
	'customers/editCustomer',
	'customers/deleteCustomer',
	//
	'persist/PURGE',
] as const

export type SyncAction = Action & {
	type: (typeof actionTypes)[number]
	meta?: {
		isSync?: boolean
	}
}

export const crossTabSync: Middleware = store => {
	const channel = new BroadcastChannel('polly-store-sync')

	channel.onmessage = event => {
		const action = event.data as SyncAction
		if (action?.type && actionTypes.includes(action.type)) {
			store.dispatch({ ...action, meta: { ...action.meta, isSync: true } })
		}
	}

	return next => action => {
		const result = next(action)
		const typedAction = action as SyncAction
		if (
			typedAction?.type &&
			actionTypes.includes(typedAction.type as (typeof actionTypes)[number]) &&
			!typedAction.meta?.isSync
		) {
			channel.postMessage(action)
		}
		return result
	}
}
