import { useState } from 'react'

import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import {
	AlertCircle,
	AlertTriangle,
	CheckCircle2,
	Download,
	File,
	Info,
	Loader2,
	RefreshCw,
	Trash2,
	UploadIcon,
} from 'lucide-react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Switch } from '@/components/ui/switch'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { createCustomer } from '@/features/customers/customers-slice'
import {
	generateOrderImportTemplate,
	importOrders,
	OrderImportResult,
} from '@/features/orders/lib/import-orders'
import { createOrder, editOrder } from '@/features/orders/orders-slice'
import { validatePlan } from '@/features/planning/planning-slice'
import { cn } from '@/lib/utils'
import { formatNumericString } from '@/utils/format-numeric-string'
import { pluralize } from '@/utils/pluralize'

type ImportOrdersDialogProps = {
	open: boolean
	onClose: () => void
}

type ImportStep = 'upload' | 'review'

function UpdateExistingOrdersSetting({
	checked,
	onChange,
}: {
	checked: boolean
	onChange: (checked: boolean) => void
}) {
	return (
		<Card>
			<CardContent className="p-6">
				<div className="flex items-center justify-between space-y-0">
					<div className="space-y-1">
						<div className="flex items-center gap-2">
							<RefreshCw className="h-4 w-4 text-muted-foreground" />
							<Label>Update Existing Orders</Label>
						</div>
						<p className="text-sm text-muted-foreground">
							When enabled, existing orders will be updated with new data.
						</p>
					</div>
					<Switch checked={checked} onCheckedChange={onChange} />
				</div>
			</CardContent>
		</Card>
	)
}

export function ImportOrdersDialog({ open, onClose }: ImportOrdersDialogProps) {
	const dispatch = useAppDispatch()

	const [state, setState] = useState<{
		step: ImportStep
		file: File | null
		previewResult: OrderImportResult | null
		isImporting: boolean
		updateExisting: boolean
	}>({
		step: 'upload',
		file: null,
		previewResult: null,
		isImporting: false,
		updateExisting: false,
	})

	const products = useAppSelector(state => state.products.list)
	const existingOrders = useAppSelector(state => state.orders.entities)

	const resetState = () => {
		setState({
			step: 'upload',
			file: null,
			previewResult: null,
			isImporting: false,
			updateExisting: false,
		})
	}

	const handleClose = () => {
		resetState()
		onClose()
	}

	const handleUpdateExistingChange = (checked: boolean) => {
		setState(prev => ({ ...prev, updateExisting: checked }))

		if (state.file) {
			analyzeFile(state.file, checked ? 'overwrite' : 'skip')
		}
	}

	const analyzeFile = async (
		file: File,
		duplicateHandling: 'skip' | 'overwrite',
	) => {
		try {
			const preview = await importOrders(file, {
				onDuplicate: duplicateHandling,
				products,
				existingOrders: Object.values(existingOrders).filter(
					(o): o is NonNullable<typeof o> => Boolean(o),
				),
				previewOnly: true,
			})
			setState(prev => ({
				...prev,
				file,
				previewResult: preview,
			}))
		} catch {
			toast.error('Failed to analyze file')
			resetState()
		}
	}

	const handleFileSelect = async (files: File[]) => {
		const file = files[0]
		if (!file) return

		await analyzeFile(file, state.updateExisting ? 'overwrite' : 'skip')
		setState(prev => ({ ...prev, step: 'review' }))
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			'text/csv': ['.csv'],
		},
		maxFiles: 1,
		multiple: false,
		maxSize: 1 * 1024 * 1024, // 1mb
		onDrop: handleFileSelect,
		disabled: state.isImporting,
	})

	const handleImport = async () => {
		if (!state.file || !state.previewResult) return

		setState(prev => ({ ...prev, isImporting: true }))

		try {
			const result = await importOrders(state.file, {
				onDuplicate: state.updateExisting ? 'overwrite' : 'skip',
				products,
				existingOrders: Object.values(existingOrders).filter(
					(o): o is NonNullable<typeof o> => Boolean(o),
				),
			})

			if (result.actions.length > 0) {
				result.actions.forEach(action => {
					if (action.type === 'create') {
						dispatch(createOrder(action.order))
						dispatch(createCustomer({ name: action.order.customerName }))
					} else if (action.type === 'update') {
						dispatch(
							editOrder({
								id: action.order.id!,
								changes: action.order,
							}),
						)
					}
				})

				dispatch(validatePlan())

				const createCount = result.actions.filter(
					a => a.type === 'create',
				).length
				const updateCount = result.actions.filter(
					a => a.type === 'update',
				).length

				toast.success(
					`Successfully imported ${createCount + updateCount} orders`,
				)
				handleClose()
			} else {
				toast.error(
					'No orders could be imported. Please check the errors and try again.',
				)
				setState(prev => ({
					...prev,
					isImporting: false,
					previewResult: result,
				}))
			}
		} catch {
			toast.error('Failed to import orders')
			setState(prev => ({ ...prev, isImporting: false }))
		}
	}

	const renderSummaryStats = () => {
		if (!state.previewResult) return null

		const createCount = state.previewResult.actions.filter(
			a => a.type === 'create',
		).length
		const updateCount = state.previewResult.actions.filter(
			a => a.type === 'update',
		).length
		const skipCount = state.previewResult.actions.filter(
			a => a.type === 'skip',
		).length
		const errorCount = state.previewResult.errors.length
		const totalCount = createCount + updateCount + skipCount + errorCount

		const stats = [
			{
				label: 'To Create',
				value: createCount,
				icon: CheckCircle2,
				variant: 'success',
				description: 'New orders',
			},
			{
				label: 'To Update',
				value: updateCount,
				icon: AlertTriangle,
				variant: 'warning',
				description: 'Updates to existing orders',
			},
			{
				label: 'To Skip',
				value: skipCount,
				icon: AlertCircle,
				variant: 'secondary',
				description: 'Orders to skip',
			},
			{
				label: 'With Errors',
				value: errorCount,
				icon: AlertCircle,
				variant: 'destructive',
				description: 'Invalid orders',
			},
		]

		return (
			<Card>
				<CardHeader className="p-6">
					<div className="flex items-center justify-between">
						<Label>Import Summary</Label>
						<span className="text-sm text-muted-foreground">
							{totalCount} {pluralize(totalCount, 'order')} found
						</span>
					</div>
				</CardHeader>
				<CardContent className="grid grid-cols-1 gap-4 p-6 pt-0 sm:grid-cols-2 lg:grid-cols-4">
					{stats.map(({ label, value, icon: Icon, variant, description }) => (
						<div
							key={label}
							className={cn(
								'relative rounded-lg border p-4 transition-opacity duration-200',
								value === 0 && 'opacity-40',
							)}
						>
							<Icon
								className={cn(
									'absolute right-4 top-4 h-8 w-8 opacity-50',
									variant === 'success' && 'text-green-600',
									variant === 'warning' && 'text-yellow-600',
									variant === 'secondary' && 'text-muted-foreground',
									variant === 'destructive' && 'text-destructive',
								)}
							/>
							<div className="space-y-2">
								<Label>{label}</Label>
								<div className="flex items-baseline gap-2">
									<p className="text-2xl font-bold">
										{formatNumericString(value)}
									</p>
									<p className="text-sm text-muted-foreground">
										{totalCount === 0
											? '0'
											: ((value / totalCount) * 100).toFixed(0)}
										%
									</p>
								</div>
								<p className="text-xs text-muted-foreground">{description}</p>
							</div>
						</div>
					))}
				</CardContent>
			</Card>
		)
	}

	const renderReviewContent = () => {
		if (!state.previewResult) return null

		return (
			<div className="space-y-6">
				<UpdateExistingOrdersSetting
					checked={state.updateExisting}
					onChange={handleUpdateExistingChange}
				/>
				{renderSummaryStats()}

				<Tabs defaultValue="overview" className="w-full">
					<TabsList className="w-full sm:w-auto">
						<TabsTrigger value="overview" className="flex-1">
							Overview
						</TabsTrigger>
						<TabsTrigger value="errors" className="flex-1">
							Errors
							{state.previewResult.summary.failed > 0 && (
								<Badge variant="destructive" className="ml-2">
									{state.previewResult.summary.failed}
								</Badge>
							)}
						</TabsTrigger>
					</TabsList>

					<TabsContent value="overview">
						<Card>
							<ScrollArea
								className={cn(
									state.previewResult.actions.length > 0
										? 'h-[300px]'
										: 'h-auto',
								)}
							>
								{state.previewResult.actions.length > 0 ? (
									<Table>
										<TableHeader>
											<TableRow>
												<TableHead className="w-[100px]">Action</TableHead>
												<TableHead>Order Number</TableHead>
												<TableHead className="hidden sm:table-cell">
													Product
												</TableHead>
												<TableHead className="hidden sm:table-cell">
													Quantity
												</TableHead>
												<TableHead className="hidden sm:table-cell">
													Due Date
												</TableHead>
											</TableRow>
										</TableHeader>
										<TableBody>
											{state.previewResult.actions.map((action, index) => (
												<TableRow
													key={index}
													className={cn(action.type === 'skip' && 'opacity-50')}
												>
													<TableCell>
														<Badge
															variant={
																action.type === 'create'
																	? 'default'
																	: action.type === 'update'
																		? 'secondary'
																		: 'outline'
															}
														>
															{action.type === 'create'
																? 'Create'
																: action.type === 'update'
																	? 'Update'
																	: 'Skip'}
														</Badge>
														{action.order.duplicateInFile && (
															<div className="mt-1 text-xs text-muted-foreground">
																Duplicate of row{' '}
																{action.order.duplicateInFile.laterRow}
															</div>
														)}
													</TableCell>
													<TableCell>
														{action.order.productionOrderNumber}
													</TableCell>
													<TableCell className="hidden sm:table-cell">
														{products.find(
															p => p.id === action.order.product.id,
														)?.name || 'Unknown'}
													</TableCell>
													<TableCell className="hidden sm:table-cell">
														{formatNumericString(action.order.quantity)}
													</TableCell>
													<TableCell className="hidden sm:table-cell">
														{format(action.order.dueDate, 'MMM d, yyyy')}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								) : (
									<div className="flex items-center gap-4 p-6">
										<div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-yellow-500/20">
											<AlertTriangle className="h-5 w-5 text-yellow-600" />
										</div>
										<div>
											<h3 className="font-medium">No Orders to Import</h3>
											<p className="text-sm text-muted-foreground">
												No valid orders were found in the CSV file. Please check
												for any validation errors and ensure the file contains
												properly formatted order data.
											</p>
										</div>
									</div>
								)}
							</ScrollArea>
						</Card>
					</TabsContent>

					<TabsContent value="errors">
						<Card>
							<ScrollArea
								className={cn(
									state.previewResult.errors.length > 0
										? 'h-[300px]'
										: 'h-auto',
								)}
							>
								{state.previewResult.errors.length > 0 ? (
									<div className="space-y-6 p-6">
										<div className="flex items-center gap-2">
											<AlertCircle className="h-5 w-5 text-destructive" />
											<h3 className="font-medium">Import Errors</h3>
										</div>

										<div className="space-y-2">
											{state.previewResult.errors.map((error, index) => (
												<div
													key={index}
													className="flex items-start gap-4 rounded-md border border-destructive/50 bg-destructive/5 p-4 text-sm"
												>
													<AlertTriangle className="mt-0.5 h-4 w-4 text-destructive" />
													<div>
														{error.row > 0 && (
															<div className="font-medium">Row {error.row}</div>
														)}
														<p className="text-muted-foreground">
															{error.message}
														</p>
													</div>
												</div>
											))}
										</div>
									</div>
								) : (
									<div className="flex items-center gap-6 p-6">
										<div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-green-500/20">
											<CheckCircle2 className="h-5 w-5 text-green-600" />
										</div>
										<div>
											<h3 className="font-medium">No Errors Found</h3>
											<p className="text-sm text-muted-foreground">
												All rows in the CSV file are valid and ready to be
												imported.
											</p>
										</div>
									</div>
								)}
							</ScrollArea>
						</Card>
					</TabsContent>
				</Tabs>
			</div>
		)
	}

	const handleDownloadTemplate = () => {
		const template = generateOrderImportTemplate()
		const blob = new Blob([template], { type: 'text/csv' })
		saveAs(blob, 'polly-order-import-template.csv')
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-h-[90vh] max-w-4xl overflow-y-auto sm:max-h-[85vh]">
				<DialogHeader>
					<DialogTitle>Import Orders</DialogTitle>
					<DialogDescription>
						Upload a CSV file to import orders. Make sure your file includes all
						required columns.
					</DialogDescription>
				</DialogHeader>

				<div className="grid gap-6 py-4">
					{state.step === 'upload' && (
						<div className="grid gap-4">
							<UpdateExistingOrdersSetting
								checked={state.updateExisting}
								onChange={handleUpdateExistingChange}
							/>

							<Card>
								<CardContent className="p-6">
									<div className="flex flex-col items-center gap-6">
										{!state.file ? (
											<>
												<div
													{...getRootProps()}
													className={cn(
														'flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed p-8 text-center transition-all duration-200',
														isDragActive
															? 'border-primary bg-primary/5'
															: 'border-muted-foreground/25 hover:border-primary/50 hover:bg-muted/50',
													)}
												>
													<input {...getInputProps()} />
													<div className="flex h-12 w-12 items-center justify-center rounded-full bg-muted">
														<UploadIcon className="h-6 w-6 text-primary" />
													</div>
													<div className="mt-4 grid gap-1">
														<p className="font-medium">
															Drop your CSV file here or click to browse
														</p>
														<p className="text-sm text-muted-foreground">
															Supports CSV files up to 1MB
														</p>
													</div>
												</div>
												<Card className="w-full">
													<CardContent className="p-6">
														<div className="flex items-center gap-2 font-medium">
															<Info className="h-4 w-4" />
															Required Columns
														</div>
														<p className="mt-1 text-sm text-muted-foreground">
															Production Order Number, Sales Order Number,
															Customer Name, Product Number, Product Name,
															Quantity, Due Date
														</p>
													</CardContent>
												</Card>
											</>
										) : (
											<div className="flex items-center gap-4 rounded-md border bg-muted/50 p-4 transition-colors hover:bg-muted">
												<div className="flex h-10 w-10 items-center justify-center rounded-lg bg-muted">
													<File className="h-5 w-5" />
												</div>
												<div>
													<p className="font-medium">{state.file.name}</p>
													<p className="text-sm text-muted-foreground">
														{(state.file.size / 1024).toFixed(1)} KB
													</p>
												</div>
												<Button
													variant="ghost"
													size="sm"
													onClick={() => {
														setState(prev => ({
															...prev,
															file: null,
															previewResult: null,
														}))
													}}
												>
													<Trash2 className="h-4 w-4 text-destructive" />
													<span className="sr-only">Remove file</span>
												</Button>
											</div>
										)}

										<div className="flex flex-wrap gap-2">
											{!state.file && (
												<Button
													variant="outline"
													onClick={handleDownloadTemplate}
													className="gap-2"
												>
													<Download className="h-4 w-4" />
													Download Template
												</Button>
											)}
											{state.file && state.previewResult && (
												<Button
													onClick={() =>
														setState(prev => ({ ...prev, step: 'review' }))
													}
												>
													Continue to Review
												</Button>
											)}
										</div>
									</div>
								</CardContent>
							</Card>
						</div>
					)}

					{state.step === 'review' &&
						state.previewResult &&
						renderReviewContent()}
				</div>

				<DialogFooter>
					{state.step === 'review' && (
						<div className="flex w-full flex-col-reverse gap-2 sm:flex-row sm:justify-end">
							<Button
								variant="outline"
								onClick={() => setState(prev => ({ ...prev, step: 'upload' }))}
								disabled={state.isImporting}
							>
								Back
							</Button>
							<Button
								onClick={handleImport}
								disabled={
									!state.previewResult ||
									state.previewResult.actions.filter(a => a.type !== 'skip')
										.length === 0 ||
									state.isImporting
								}
							>
								{state.isImporting ? (
									<>
										<Loader2 className="mr-2 h-4 w-4 animate-spin" />
										Importing...
									</>
								) : (
									<>
										Import Orders
										<Badge variant="secondary" className="ml-2">
											{formatNumericString(
												state.previewResult?.actions.filter(
													a => a.type !== 'skip',
												).length ?? 0,
											)}
										</Badge>
									</>
								)}
							</Button>
						</div>
					)}
					{state.step !== 'review' && (
						<Button variant="outline" onClick={handleClose}>
							{state.file ? 'Cancel Import' : 'Cancel'}
						</Button>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
