const parseFlexibleNumber = (
	value: string,
	errorMessage: string = 'Invalid number format. Please enter a valid number.',
) => {
	// Remove any spaces that might be used as thousand separators
	value = value.replace(/\s/g, '')

	if (value === '') {
		return 0
	}

	// Determine if the format uses a period or a comma as the decimal separator
	const lastCommaIndex = value.lastIndexOf(',')
	const lastPeriodIndex = value.lastIndexOf('.')

	if (lastCommaIndex > -1 && lastPeriodIndex > -1) {
		// If both comma and period are present, use the one that appears last as the decimal separator
		if (lastCommaIndex > lastPeriodIndex) {
			// Comma is the decimal separator
			value = value.replace(/\./g, '') // Remove periods (thousand separators)
			value = value.replace(/,/g, '.') // Replace comma with period
		} else {
			// Period is the decimal separator
			value = value.replace(/,/g, '') // Remove commas (thousand separators)
		}
	} else if (lastCommaIndex > -1) {
		// Only commas are present, treat the last one as the decimal separator
		value = value.replace(/,(?=\d{3}(\D|$))/g, '') // Remove commas except the last one
		value = value.replace(/,/g, '.') // Replace the last comma with period
	}

	// Parse the cleaned value as a float
	const parsed = parseFloat(value)

	// Validate the parsed number
	if (isNaN(parsed)) {
		throw new Error(errorMessage)
	}

	return parsed
}

export { parseFlexibleNumber }
