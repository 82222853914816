import React, {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'
import {
	TCalendarAdjustment,
	TCalendarPeriodAdjustment,
	TCompletedOrder,
	TInProgressOrder,
	TMachine,
	TMachineAvailability,
	TMachineBooking,
	TOrder,
	TPeriod,
	TPeriodFlexible,
	TPlannedOrder,
	TProduct,
} from '@repo/types'
import { Link } from '@tanstack/react-router'
import {
	addMinutes,
	compareAsc,
	differenceInHours,
	differenceInMinutes,
	eachHourOfInterval,
	format,
	getHours,
	isAfter,
	isMonday,
	isSameDay,
	isWithinInterval,
	min,
	roundToNearestMinutes,
} from 'date-fns'
import {
	ArrowLeft,
	ArrowRight,
	ArrowUpDown,
	Bell,
	BellOff,
	Home,
	Info,
	Magnet,
	Pencil,
	Play,
	ZoomIn,
	ZoomOut,
} from 'lucide-react'

import { useTheme } from '@/components/theme-provider'
import { Button } from '@/components/ui/button'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { AlertIcon } from '@/features/alerts/components/alert-icon'
import { adjustOpenPeriods } from '@/features/planning/adjust-open-periods'
import { getCalendarAdjustmentsForMachine } from '@/features/planning/get-calendar-adjustments-for-machine'
import { getOpenPeriods } from '@/features/planning/get-open-periods'
import { invertPeriods } from '@/features/planning/invert-periods'
import { getAlertsForMachine } from '@/features/validation/get-alerts-for-machine'
import { TPlanningAlert } from '@/features/validation/types'
import { cn } from '@/lib/utils'
import { formatNumericString } from '@/utils/format-numeric-string'
import { formatTimeUnit } from '@/utils/format-time-unit'

import { useBookingActionBar } from '../hooks/use-booking-action-bar'
import { useDragToScroll } from '../hooks/use-drag-to-scroll'
import { usePreventOverscrollX } from '../hooks/use-prevent-overscroll-x'
import { useScrollSync } from '../hooks/use-scroll-sync'
import { SortableMachineList } from './sortable-machine-list'

export function GanttRow(
	props: {
		children: React.ReactNode
		height: number
	} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'className'>,
) {
	const { children, height, style, className } = props
	return (
		<div
			style={{ height, ...style }}
			className={cn(
				'my-1 flex w-full items-center px-[8px] py-[4px]',
				className,
			)}
		>
			{children}
		</div>
	)
}

function FadeOut(props: { width: number }) {
	const { width } = props
	return (
		<div
			style={{
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				zIndex: 40,
				width,
				background:
					'linear-gradient(to right, hsl(var(--background)) 25%, rgba(255,255,255,0))',
				pointerEvents: 'none',
			}}
		/>
	)
}

const XLabels = forwardRef(function XLabels(
	props: {
		startDate: Date
		endDate: Date
		hourGap: number
		height: number
		width: number
		step?: number
		zoomLevel: string
	},
	ref: React.Ref<HTMLDivElement>,
) {
	const {
		startDate,
		endDate,
		hourGap,
		height,
		width,
		step = 1,
		zoomLevel,
	} = props

	const hours = useMemo(() => {
		const hours = eachHourOfInterval(
			{
				start: startDate,
				end: endDate,
			},
			{ step },
		)

		// Calculate label positions and detect collisions
		const minGapBetweenLabels = 60
		const labels: Array<{
			topLabel?: string
			middleLabel?: string
			bottomLabel?: string
			position: number
			show: boolean
			importance: {
				top?: 1 | 2 | 3
				middle?: 1 | 2 | 3
				bottom?: 1 | 2 | 3
			}
		}> = []

		let lastShownPosition = -Infinity
		let lastLabels = { top: '', middle: '', bottom: '' }

		hours.forEach((date, index) => {
			const position = hourGap * index * step

			const hour = getHours(date)

			// Determine if this is a significant time point
			const isStartOfDay = hour === 0
			const isStartOfWeek = isMonday(date) && hour === 0

			// Calculate labels and their individual importance
			let topLabel = undefined
			let middleLabel = undefined
			let bottomLabel = undefined
			let importance: {
				top?: 1 | 2 | 3
				middle?: 1 | 2 | 3
				bottom?: 1 | 2 | 3
			} = {}

			switch (zoomLevel) {
				case '12h':
				case '24h':
					if (isStartOfDay) {
						topLabel = format(date, "'W'I RRRR")
						middleLabel = format(date, 'EEE')
						bottomLabel = format(date, 'MMM d')
						importance = { top: 1, middle: 3, bottom: 2 }
					} else {
						bottomLabel = format(date, 'HH:mm')
						importance = {
							bottom: [6, 12, 18].includes(hour) ? 2 : 1,
						}
					}
					break

				case '3d':
					if (isStartOfDay) {
						topLabel = format(date, "'W'I RRRR")
						middleLabel = format(date, 'EEE')
						bottomLabel = format(date, 'MMM d')
						importance = { top: 1, middle: 3, bottom: 2 }
					} else if ([3, 6, 9, 15, 18, 21].includes(date.getHours())) {
						bottomLabel = format(date, 'HH:mm')
						importance = { bottom: 1 }
					} else if ([12].includes(date.getHours())) {
						bottomLabel = format(date, 'HH:mm')
						importance = { bottom: 2 }
					}
					break

				case '5d':
					if (isStartOfDay) {
						topLabel = format(date, "'W'I RRRR")
						middleLabel = format(date, 'EEE')
						bottomLabel = format(date, 'MMM d')
						importance = { top: 1, middle: 3, bottom: 2 }
					} else if ([6, 12, 18].includes(date.getHours())) {
						bottomLabel = format(date, 'HH:mm')
						importance = { bottom: 1 }
					}
					break

				case '14d':
					if (isStartOfWeek) {
						topLabel = format(date, "'W'I RRRR")
						middleLabel = format(date, 'EEE')
						bottomLabel = format(date, 'MMM d')
						importance = { top: 1, middle: 3, bottom: 2 }
					} else if (isStartOfDay) {
						middleLabel = format(date, 'EEE')
						bottomLabel = format(date, 'MMM d')
						importance = { middle: 1, bottom: 2 }
					}
					break

				case '30d':
					if (isStartOfWeek) {
						topLabel = format(date, "'W'I RRRR")
						middleLabel = format(date, 'EEE')
						bottomLabel = format(date, 'MMM d')
						importance = { top: 1, middle: 3, bottom: 2 }
					} else if (isStartOfDay && [1, 3, 5].includes(date.getDay())) {
						middleLabel = format(date, 'EEE')
						bottomLabel = format(date, 'MMM d')
						importance = { middle: 1, bottom: 2 }
					}
					break

				case '90d':
					if (isStartOfWeek) {
						topLabel = format(date, "'W'I RRRR")
						middleLabel = format(date, 'EEE')
						bottomLabel = format(date, 'MMM d')
						importance = { top: 1, middle: 3, bottom: 2 }
					}
					break
			}

			// Determine if this label should be shown
			const hasEnoughSpace = position - lastShownPosition >= minGapBetweenLabels
			const isNewInformation =
				topLabel !== lastLabels.top ||
				middleLabel !== lastLabels.middle ||
				bottomLabel !== lastLabels.bottom
			const hasImportance = Object.keys(importance).length > 0
			const show = hasEnoughSpace && isNewInformation && hasImportance

			if (show) {
				lastShownPosition = position
				lastLabels = {
					top: topLabel ?? '',
					middle: middleLabel ?? '',
					bottom: bottomLabel ?? '',
				}
			}

			if (topLabel || middleLabel || bottomLabel) {
				labels.push({
					topLabel,
					middleLabel,
					bottomLabel,
					position,
					show,
					importance,
				})
			}
		})

		// Second pass: try to show more labels if there's space
		for (let i = 0; i < labels.length; i++) {
			const label = labels[i]
			if (!label.show && Object.keys(label.importance).length > 0) {
				const prev = labels
					.slice(0, i)
					.reverse()
					.find(l => l.show)
				const next = labels.slice(i + 1).find(l => l.show)

				if (prev && next) {
					const hasSpaceBefore =
						label.position - prev.position >= minGapBetweenLabels
					const hasSpaceAfter =
						next.position - label.position >= minGapBetweenLabels

					if (hasSpaceBefore && hasSpaceAfter) {
						label.show = true
					}
				}
			}
		}

		return labels
	}, [hourGap, startDate, endDate, step, zoomLevel])

	return (
		<div
			ref={ref}
			className="relative bg-background"
			style={{ width, minHeight: height }}
		>
			{hours.map(
				(
					{ topLabel, middleLabel, bottomLabel, position, show, importance },
					index,
				) =>
					show && (
						<div
							key={index}
							className="absolute flex flex-col items-center"
							style={{
								bottom: 4,
								left: position - 40,
								width: 80,
							}}
						>
							{topLabel && (
								<span
									className={cn(
										'text-xs',
										importance.top === 3 && 'font-medium',
										importance.top === 2 && 'font-normal',
										importance.top === 1 && 'text-muted-foreground',
									)}
								>
									{topLabel}
								</span>
							)}
							{middleLabel && (
								<span
									className={cn(
										'text-xs',
										importance.middle === 3 && 'font-medium',
										importance.middle === 2 && 'font-normal',
										importance.middle === 1 && 'text-muted-foreground',
									)}
								>
									{middleLabel}
								</span>
							)}
							{bottomLabel && (
								<span
									className={cn(
										'text-xs',
										importance.bottom === 3 && 'font-medium',
										importance.bottom === 2 && 'font-normal',
										importance.bottom === 1 && 'text-muted-foreground',
									)}
								>
									{bottomLabel}
								</span>
							)}
						</div>
					),
			)}
			<Separator style={{ position: 'absolute', bottom: 0 }} />
		</div>
	)
})

function GridLines(props: {
	startDate: Date
	endDate: Date
	hourGap: number
	step?: number
}) {
	const { startDate, endDate, hourGap, step = 1 } = props

	const hours = useMemo(() => {
		const hours = eachHourOfInterval(
			{
				start: startDate,
				end: endDate,
			},
			{ step },
		)
		return hours
	}, [startDate, endDate, step])

	return hours.map((_, index) => (
		<Separator
			key={index}
			orientation="vertical"
			style={{
				position: 'absolute',
				top: 0,
				left: hourGap * index * step - 0.5,
			}}
		/>
	))
}

const NowLine = forwardRef(function NowLine(
	props: {
		startDate: Date
		hourGap: number
	},
	ref: React.Ref<HTMLDivElement>,
) {
	const { startDate, hourGap } = props
	const [now, setNow] = useState(new Date())
	const [offset, setOffset] = useState(0)
	const width = 2
	const left = offset

	useEffect(() => {
		const interval = setInterval(() => {
			setNow(new Date())
		}, 10_000)
		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		const differenceInHours = differenceInMinutes(now, startDate) / 60
		const offset = differenceInHours * hourGap - width / 2
		setOffset(offset)
	}, [now, startDate, hourGap])

	return (
		<Separator
			ref={ref}
			orientation="vertical"
			className="bg-slate-900/50 dark:bg-slate-100/50"
			style={{
				position: 'absolute',
				top: 0,
				width,
				left,
			}}
		/>
	)
})

function ClosedPeriod(props: {
	startDate: Date
	period: TPeriod
	hourGap: number
	height: number
}) {
	const { startDate, period, hourGap, height } = props
	const width =
		(differenceInMinutes(period.endDate, period.startDate) / 60) * hourGap
	const left = (differenceInMinutes(period.startDate, startDate) / 60) * hourGap
	return (
		<div
			className="absolute bg-slate-200/50 dark:bg-slate-700/50"
			style={{
				left,
				width,
				height,
			}}
		/>
	)
}

function ClosedPeriods(props: {
	availability: TMachineAvailability
	calendarAdjustments: TCalendarPeriodAdjustment[]
	startDate: Date
	endDate: Date
	hourGap: number
	height: number
}) {
	const {
		availability,
		calendarAdjustments,
		startDate,
		endDate,
		hourGap,
		height,
	} = props

	const closedPeriods = useMemo(() => {
		const openPeriods = getOpenPeriods({ startDate, endDate, availability })
		const adjustedOpenPeriods = adjustOpenPeriods({
			startDate,
			endDate,
			openPeriods,
			calendarAdjustments,
		})
		return invertPeriods({
			startDate,
			endDate,
			periods: adjustedOpenPeriods,
		})
	}, [availability, calendarAdjustments, startDate, endDate])

	return closedPeriods.map(period => (
		<ClosedPeriod
			key={`${period.startDate.getTime()}-${period.endDate.getTime()}`}
			startDate={startDate}
			period={period}
			hourGap={hourGap}
			height={height}
		/>
	))
}

const dragImage = new Image()
dragImage.src =
	'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='

function BookedPeriod(props: {
	startDate: Date
	endDate: Date
	product?: TProduct
	order?: TPlannedOrder | TInProgressOrder | TCompletedOrder
	booking: TMachineBooking
	hourGap: number
	height: number
	viewRef: React.RefObject<HTMLElement>
	active: boolean
	muted: boolean
	onChangeBookingStartDate?: () => void
	onMoveBooking?: (args: {
		booking: TMachineBooking
		desiredStartDate: Date
	}) => void
	onUpdateProgress?: () => void
	onSelect?: (args: { orderId: string; bookingId: string }) => void
	onSelectNext?: (bookingId: string) => void // TODO: Implement like `onUpdateProgress` without a `bookingId`
	onSelectPrev?: (bookingId: string) => void // TODO: Implement like `onUpdateProgress` without a `bookingId`
	onSwitchBookingMachine?: () => void
	onAlignBookings?: () => void
	onEditOrder?: () => void
}) {
	const {
		startDate,
		endDate,
		order,
		product,
		booking,
		hourGap,
		height,
		viewRef,
		active,
		muted,
		onMoveBooking,
		onChangeBookingStartDate,
		onUpdateProgress,
		onSelect,
		onSelectNext,
		onSelectPrev,
		onSwitchBookingMachine,
		onAlignBookings,
		onEditOrder,
	} = props
	const { darkMode } = useTheme()
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const [tooltipOffsetX, setTooltipOffsetX] = useState(0)
	const [tooltipLocation, setTooltipLocation] = useState<'top' | 'bottom'>(
		'bottom',
	)
	const outerRef = useRef<HTMLDivElement>(null)
	const tooltipRef = useRef<HTMLDivElement>(null)

	const tooltipWidth = 300
	const tooltipHeight = 208
	const gap = 4
	const { before, during, after } = booking.phases

	const calculateWidth = useCallback(
		(period: TPeriodFlexible) => {
			const minutes = differenceInMinutes(period.endDate, period.startDate)
			return (minutes / 60) * hourGap
		},
		[hourGap],
	)

	const diagonalStripePattern = useMemo(
		() =>
			darkMode
				? `repeating-linear-gradient(
		-45deg,
		transparent,
		transparent 4px,
		rgb(255 255 255 / 0.25) 4px,
		rgb(255 255 255 / 0.25) 8px
	  )`
				: `repeating-linear-gradient(
		-45deg,
		transparent,
		transparent 4px,
		rgb(0 0 0 / 0.15) 4px,
		rgb(0 0 0 / 0.15) 8px
	  )`,
		[darkMode],
	)

	const productionOrderNumber = order?.productionOrderNumber ?? 'Unknown Order'
	const customerName = order?.customerName ?? 'Unknown Customer'
	const productNumber = product?.productNumber ?? 'Unknown Product'
	const productName = product?.name ?? 'Unknown Product'
	const quantity = order?.planningParameters.quantity ?? 0

	const [dragState, setDragState] = useState<{
		initialScrollLeft: number
		initialPointX: number
		offset: number
		desiredStartDate: Date
	} | null>(null)

	const { actionBar, actionBarWidth, hasNext, hasPrev } = useBookingActionBar({
		booking,
		bookingActive: active,
		order,
		hourGap,
		dragState,
		bookingRef: outerRef,
		viewRef,
		actionBarWidth: 230,
	})

	const endsAfter = useMemo(
		() => isAfter(booking.endDate, endDate),
		[booking.endDate, endDate],
	)

	const {
		offsetX,
		effectiveWidth,
		actualWidth,
		setupWidth,
		productionWidth,
		teardownWidth,
	} = useMemo(
		() => ({
			offsetX: calculateWidth({
				startDate,
				endDate: booking.startDate,
			}),
			effectiveWidth: (booking.effectiveTimeMinutes?.total / 60) * hourGap,
			actualWidth: calculateWidth({
				startDate: booking.startDate,
				endDate: min([booking.endDate, endDate]),
			}),
			setupWidth: calculateWidth(before),
			productionWidth: calculateWidth(during),
			teardownWidth: calculateWidth(after),
		}),
		[
			startDate,
			endDate,
			booking,
			hourGap,
			calculateWidth,
			before,
			during,
			after,
		],
	)

	const handleTooltipInteractionStart = useCallback(
		(event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
			event.preventDefault()
			if (dragState !== null) return
			const x = outerRef.current?.getBoundingClientRect().left
			const startPointX =
				'touches' in event ? event.touches[0].clientX : event.clientX
			const startPointY =
				'touches' in event ? event.touches[0].clientY : event.clientY
			const offsetX = x ? startPointX - x - tooltipWidth / 2 : 0
			const tooltipLocation =
				window.innerHeight - startPointY - 60 < height * 2 + tooltipHeight + gap
					? 'top'
					: 'bottom'

			setTooltipOpen(true)
			setTooltipOffsetX(offsetX)
			setTooltipLocation(tooltipLocation)
		},
		[dragState, height],
	)

	const handleTooltipInteractionEnd = useCallback(
		(event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
			event.preventDefault()
			setTooltipOpen(false)
			setTooltipOffsetX(0)
		},
		[],
	)

	const handleSelection = useCallback(
		(event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
			const isMouseEvent = !(event as React.TouchEvent<HTMLElement>).touches
			if (isMouseEvent) {
				event.stopPropagation()
			}
			onSelect?.({ orderId: booking.orderId, bookingId: booking.id })
		},
		[booking.id, booking.orderId, onSelect],
	)

	const handleSelectNext = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation()
			onSelectNext?.(booking.id)
		},
		[booking.id, onSelectNext],
	)

	const handleSelectPrev = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation()
			onSelectPrev?.(booking.id)
		},
		[booking.id, onSelectPrev],
	)

	const unifiedDragStart = useCallback(
		(event: React.DragEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
			if ('touches' in event) {
				event.preventDefault()
			} else {
				event.dataTransfer.setDragImage(dragImage, 0, 0)
			}
			setTooltipOpen(false)
			const initialScrollLeft = viewRef.current?.scrollLeft ?? 0
			const initialPointX =
				'touches' in event ? event.touches[0].clientX : event.clientX
			setDragState({
				initialScrollLeft,
				initialPointX,
				offset: 0,
				desiredStartDate: new Date(booking.startDate),
			})
		},
		[booking.startDate, viewRef],
	)

	const unifiedDragMove = useCallback(
		(event: React.DragEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
			if ('touches' in event) {
				event.preventDefault()
			}

			setDragState(prev => {
				const scrollLeft = viewRef.current?.scrollLeft ?? 0
				const currentPointX =
					'touches' in event ? event.touches[0].clientX : event.clientX
				const initialScrollLeft = prev?.initialScrollLeft ?? 0
				const initialPointX = prev?.initialPointX ?? 0
				if (currentPointX === 0) return prev
				const offset =
					scrollLeft - initialScrollLeft + currentPointX - initialPointX
				const desiredStartDate = roundToNearestMinutes(
					prev
						? addMinutes(booking.startDate, (prev.offset / hourGap) * 60)
						: booking.startDate,
				)
				return {
					initialScrollLeft,
					initialPointX,
					offset,
					desiredStartDate,
				}
			})
		},
		[booking.startDate, hourGap, viewRef],
	)

	const unifiedDragEnd = useCallback(() => {
		if (dragState) {
			onMoveBooking?.({
				booking,
				desiredStartDate: roundToNearestMinutes(
					addMinutes(booking.startDate, (dragState.offset / hourGap) * 60),
				),
			})
		}
		setDragState(null)
	}, [booking, dragState, hourGap, onMoveBooking])

	return (
		<div
			tabIndex={0}
			className={cn(
				'absolute flex cursor-auto select-none items-center justify-between rounded-md hover:shadow-md',
				active && 'shadow-md',
				onSelect && 'cursor-pointer',
			)}
			style={{
				left: offsetX + (dragState?.offset ?? 0),
				width: actualWidth,
				height,
			}}
			ref={outerRef}
			onMouseDownCapture={handleSelection}
			onTouchStart={handleSelection}
			onClick={handleSelection}
			onMouseUpCapture={event => event.stopPropagation()}
		>
			{effectiveWidth < actualWidth && (
				<div
					className={cn(
						'pointer-events-none invisible absolute z-30 flex h-full items-center overflow-hidden rounded-md bg-foreground/50',
						dragState && 'visible',
					)}
					style={{
						left: effectiveWidth - 1,
						width: 2,
					}}
				/>
			)}
			{actionBar.active && (
				<div
					data-interactive-zone="true"
					className="absolute z-30 flex justify-center gap-1"
					style={{
						top: actionBar.top,
						left: actionBar.left,
						width: actionBarWidth,
					}}
				>
					<Button
						title="Change Start Date"
						variant="outline"
						className="h-auto bg-background p-1 font-mono text-xs leading-none text-foreground shadow-md"
						disabled={!onChangeBookingStartDate}
						onClick={onChangeBookingStartDate}
					>
						{format(
							dragState?.desiredStartDate ?? booking.startDate,
							'MMM d, HH:mm',
						)}
						{!dragState && (
							<>
								&mdash;
								{isSameDay(booking.startDate, booking.endDate)
									? format(booking.endDate, 'HH:mm')
									: format(booking.endDate, 'MMM d, HH:mm')}
							</>
						)}
					</Button>
					<Button
						title="More Info"
						variant="outline"
						className="h-auto cursor-default bg-background p-1 leading-none text-foreground shadow-md"
						onMouseEnter={handleTooltipInteractionStart}
						onMouseLeave={handleTooltipInteractionEnd}
						onFocus={() => setTooltipOpen(true)}
						onBlur={() => setTooltipOpen(false)}
						onTouchStart={handleTooltipInteractionStart}
						onTouchEnd={handleTooltipInteractionEnd}
					>
						<Info className="h-4 w-4" />
					</Button>
					{onUpdateProgress && (
						<Button
							title="Update Progress"
							variant="outline"
							className="h-auto bg-background p-1 leading-none text-foreground shadow-md"
							onClick={onUpdateProgress}
						>
							<Play className="h-4 w-4" />
						</Button>
					)}
					<Button
						title="Align Bookings"
						variant="outline"
						className="h-auto bg-background p-1 leading-none text-foreground shadow-md"
						disabled={!onAlignBookings}
						onClick={onAlignBookings}
					>
						<Magnet className="h-4 w-4" />
					</Button>
					{onEditOrder && order && (
						<Button
							title="Edit Order"
							variant="outline"
							className="h-auto bg-background p-1 leading-none text-foreground shadow-md"
							onClick={() => onEditOrder?.()}
						>
							<Pencil className="h-4 w-4" />
						</Button>
					)}
					<Button
						title="Switch Machine"
						variant="outline"
						className="h-auto bg-background p-1 leading-none text-foreground shadow-md"
						disabled={!onSwitchBookingMachine}
						onClick={onSwitchBookingMachine}
					>
						<ArrowUpDown className="h-4 w-4" />
					</Button>
					{(hasNext || hasPrev) && (
						<>
							<Button
								title="Previous Operation"
								variant="outline"
								className="h-auto bg-background p-1 leading-none text-foreground shadow-md"
								disabled={!hasPrev}
								onClick={handleSelectPrev}
							>
								<ArrowLeft className="h-4 w-4" />
							</Button>
							<Button
								title="Next Operation"
								variant="outline"
								className="h-auto bg-background p-1 leading-none text-foreground shadow-md"
								disabled={!hasNext}
								onClick={handleSelectNext}
							>
								<ArrowRight className="h-4 w-4" />
							</Button>
						</>
					)}
				</div>
			)}
			<div
				className={cn(
					'relative z-10 flex h-full w-full touch-none items-center overflow-hidden rounded-md bg-slate-400/50 text-xs shadow-[0_0_0_1px_rgba(255,255,255,0.5)] hover:z-20 hover:shadow-[0_0_0_1px_rgba(0,0,0,0.5)] dark:shadow-[0_0_0_1px_rgba(0,0,0,0.5)] dark:hover:shadow-[0_0_0_1px_rgba(255,255,255,0.5)]',
					endsAfter && 'rounded-e-none',
					booking.status === 'in-progress' &&
						'bg-emerald-400/50 dark:bg-emerald-600/50',
					booking.status === 'completed' &&
						'bg-background shadow-[0_0_0_1px_rgba(0,0,0,0.35)] dark:shadow-[0_0_0_1px_rgba(255,255,255,0.35)]',
					active &&
						'shadow-[0_0_0_1px_rgba(0,0,0,0.5)] dark:shadow-[0_0_0_1px_rgba(255,255,255,0.5)]',
					active && booking.status !== 'completed' && 'cursor-move',
					active && booking.status === 'completed' && 'cursor-not-allowed',
					muted && 'opacity-50',
					dragState && 'z-20',
				)}
				draggable={booking.status !== 'completed'}
				onDragStart={unifiedDragStart}
				onDrag={unifiedDragMove}
				onDragEnd={unifiedDragEnd}
				onTouchStart={unifiedDragStart}
				onTouchMove={unifiedDragMove}
				onTouchEnd={unifiedDragEnd}
			>
				{booking.status === 'in-progress' && (
					<div
						className={cn(
							'pointer-events-none absolute flex h-full items-center overflow-hidden rounded-md bg-emerald-400 dark:bg-emerald-600',
						)}
						style={{
							left: 0,
							width:
								(booking.progress.before?.completed ? setupWidth : 0) +
								productionWidth * (booking.progress.during.percentage / 100) +
								(booking.progress.after?.completed ? teardownWidth : 0),
						}}
					/>
				)}
				<div
					style={{
						width: setupWidth,
						minWidth: setupWidth,
						background: diagonalStripePattern,
					}}
					className="z-10 h-full"
				/>
				<span
					className={cn(
						'z-10 flex-grow truncate p-2 text-xs',
						booking.status === 'in-progress' && 'text-foreground/80',
					)}
				>
					{productionOrderNumber} &bull; {productNumber} &bull; {productName}
				</span>
				<div
					style={{
						width: teardownWidth,
						minWidth: teardownWidth,
						background: diagonalStripePattern,
					}}
					className="z-10 h-full"
				/>
			</div>
			{tooltipOpen && (
				// TODO: Make into card?
				<div
					ref={tooltipRef}
					className="absolute z-40 flex flex-col gap-2 rounded-md border bg-background p-2 text-xs text-foreground shadow-md"
					style={{
						width: tooltipWidth,
						left: tooltipOffsetX,
						top:
							tooltipLocation === 'top'
								? -tooltipHeight - gap - (active ? -actionBar.top : 0)
								: actionBar.top > 0
									? height + gap + actionBar.top
									: height + gap,
					}}
					onMouseEnter={() => setTooltipOpen(false)}
				>
					<ul className="w-full">
						<li className="truncate">
							<strong>Production Order Number:</strong> {productionOrderNumber}
						</li>
						<li className="truncate">
							<strong>Product:</strong> {productNumber} &bull; {productName}
						</li>
						<li className="truncate">
							<strong>Quantity:</strong> {formatNumericString(quantity)}
						</li>
						<li className="truncate">
							<strong>Customer:</strong> {customerName}
						</li>
						<li className="truncate">
							<strong>Total Duration:</strong>{' '}
							{formatNumericString(
								(
									differenceInMinutes(booking.endDate, booking.startDate) / 60
								).toFixed(2),
							)}{' '}
							hours
						</li>
						<li className="truncate">
							<strong>Eff. Production Time:</strong>{' '}
							{formatNumericString(
								(booking.effectiveTimeMinutes.during / 60).toFixed(2),
							)}{' '}
							hours
						</li>
						{order && (
							<li className="truncate">
								<strong>Order Earliest Start:</strong>{' '}
								{format(order.earliestStartDate, 'EEE MMM d yyyy')}
							</li>
						)}
						{order && (
							<li className="truncate">
								<strong>Order Due:</strong>{' '}
								{format(order.dueDate, 'EEE MMM d yyyy')}
								{order.planningParameters.buffer.quantity > 0 && (
									<>
										{' '}
										(buffer:{' '}
										{formatNumericString(
											order.planningParameters.buffer.quantity,
										)}{' '}
										{formatTimeUnit[order.planningParameters.buffer.unit](
											order.planningParameters.buffer.quantity,
										)}
										)
									</>
								)}
							</li>
						)}
						<li className="truncate">
							<strong>Start:</strong>{' '}
							{format(booking.startDate, 'EEE MMM d yyyy, HH:mm')}
						</li>
						<li className="truncate">
							<strong>End:</strong>{' '}
							{format(booking.endDate, 'EEE MMM d yyyy, HH:mm')}
						</li>
					</ul>
				</div>
			)}
		</div>
	)
}

function Alert(props: {
	alert: TPlanningAlert
	startDate: Date
	endDate: Date
	hourGap: number
	height: number
	muted: boolean
	onIgnore: () => void
	onActivate: () => void
}) {
	const {
		alert,
		startDate,
		endDate,
		hourGap,
		height,
		muted,
		onIgnore,
		onActivate,
	} = props

	const containerRef = useRef<HTMLDivElement>(null)
	const [container, setContainer] = useState<HTMLDivElement | null>(null)
	useEffect(() => {
		setContainer(containerRef.current)
	}, [])

	const calculateWidth = (period: TPeriod) =>
		(differenceInMinutes(period.endDate, period.startDate) / 60) * hourGap

	const offsetX =
		calculateWidth({
			startDate,
			endDate: new Date(alert.startDate),
		}) - 1
	const width =
		calculateWidth({
			startDate: new Date(alert.startDate),
			endDate: min([alert.endDate, endDate]),
		}) + 2

	return (
		<div
			ref={containerRef}
			tabIndex={0}
			className="absolute flex cursor-auto select-none items-center justify-center rounded-md"
			style={{
				left: offsetX,
				width,
				height,
			}}
		>
			<div
				className={cn(
					'pointer-events-none z-20 flex h-full w-full items-center justify-center rounded-md bg-destructive/50',
					muted && 'opacity-20',
					alert.ignored && 'opacity-0',
				)}
			/>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						variant="destructive"
						className={cn(
							'absolute -top-[12px] z-30 h-auto rounded-full p-1 shadow',
							alert.ignored &&
								'z-20 bg-muted text-muted-foreground opacity-50 hover:bg-muted/90',
							muted && 'z-20 opacity-20',
						)}
					>
						<AlertIcon category={alert.category} />
					</Button>
				</PopoverTrigger>
				<PopoverContent
					container={container}
					sideOffset={4}
					side="top"
					className="flex w-[96dvw] max-w-72 flex-col gap-2 p-2"
				>
					<div>
						<div className="flex items-center gap-2">
							<AlertIcon category={alert.category} />
							{alert.category === 'overlap' && 'Overlap'}
							{alert.category === 'tool-overlap' && 'Tool Overlap'}
							{alert.category === 'early' && 'Early'}
							{alert.category === 'late' && 'Late'}
							{alert.category === 'transition-soft-linked' &&
								'Transition: Soft-Linked'}
							{alert.category === 'transition-hard-linked' &&
								'Transition: Hard-Linked'}
						</div>
						<p className="text-xs text-muted-foreground">{alert.suggestion}</p>
						<ol className="mt-2 list-inside list-disc space-y-1 text-xs">
							{alert.category === 'early' && (
								<>
									<li>Move start date to later</li>
									<li>
										<Link
											className="text-primary underline decoration-primary/50 decoration-dotted underline-offset-4 hover:decoration-solid"
											to="."
											search={old => ({
												...old,
												editOrderId: alert.orders[0].id,
											})}
										>
											Adjust order <strong>earliest start date</strong>
										</Link>
									</li>
								</>
							)}
							{alert.category === 'late' && (
								<>
									<li>Move start date to earlier</li>
									<li>
										Add overtime via{' '}
										<Link
											className="text-primary underline decoration-primary/50 decoration-dotted underline-offset-4 hover:decoration-solid"
											to="/planning/calendar-adjustments/new"
										>
											calendar adjustments
										</Link>
									</li>
									<li>
										<Link
											className="text-primary underline decoration-primary/50 decoration-dotted underline-offset-4 hover:decoration-solid"
											to="."
											search={old => ({
												...old,
												editOrderId: alert.orders[0].id,
											})}
										>
											Adjust order <strong>due date</strong>
										</Link>
									</li>
								</>
							)}
						</ol>
					</div>
					<div className="flex flex-col">
						{alert.ignored ? (
							<Button
								variant="outline"
								size="sm"
								disabled={!alert.ignored}
								onClick={onActivate}
							>
								<Bell className="mr-2 h-4 w-4 shrink-0" />
								Reactivate Alert
							</Button>
						) : (
							<Button
								variant="outline"
								size="sm"
								disabled={alert.ignored}
								onClick={onIgnore}
							>
								<BellOff className="mr-2 h-4 w-4 shrink-0" />
								Ignore Alert
							</Button>
						)}
					</div>
				</PopoverContent>
			</Popover>
		</div>
	)
}

function Gantt(props: {
	scrollRef: React.RefObject<HTMLDivElement>
	zoomLevel: string
	startDate: Date
	endDate: Date
	machines: TMachine[]
	products: TProduct[]
	orders: (TPlannedOrder | TInProgressOrder | TCompletedOrder)[]
	bookings: TMachineBooking[]
	alerts: TPlanningAlert<TOrder, TMachineBooking>[]
	calendarAdjustments: TCalendarAdjustment[]
	hourGap: number
	rowHeight: number
	rowLabelWidth: number
	rowLabelMarginLeft: number
	xLabelsStep: number
	xGridLinesStep: number
	activeOrderId?: string
	activeBookingId?: string
	onAdjustMachineCalendar: (machineId: string) => void
	onReorderMachines: (args: { oldIndex: number; newIndex: number }) => void
	onSelectBooking: (args: { orderId: string; bookingId: string }) => void
	onSelectNextBooking: (bookingId: string) => void
	onSelectPrevBooking: (bookingId: string) => void
	onMoveBooking: (args: {
		booking: TMachineBooking
		desiredStartDate: Date
	}) => void
	onUpdateProgress: () => void
	onSwitchBookingMachine: () => void
	onChangeBookingStartDate: () => void
	onAlignBookings: () => void
	onEditOrder?: (orderId: string) => void
	focusViewOnDate: (date: Date) => void
	onIgnoreAlert: (alert: TPlanningAlert) => void
	onActivateAlert: (alert: TPlanningAlert) => void
	onZoomIn?: () => void
	onZoomOut?: () => void
	isHidden: (machineId: string) => boolean
	onToggleVisibility: (machineId: string) => void
}) {
	const {
		scrollRef,
		zoomLevel,
		startDate,
		endDate,
		machines,
		products,
		orders,
		bookings,
		alerts,
		calendarAdjustments,
		hourGap,
		rowHeight,
		rowLabelWidth,
		rowLabelMarginLeft,
		xLabelsStep,
		xGridLinesStep,
		activeOrderId,
		activeBookingId,
		onAdjustMachineCalendar,
		onReorderMachines,
		onSelectBooking,
		onSelectNextBooking,
		onSelectPrevBooking,
		onMoveBooking,
		onUpdateProgress,
		onSwitchBookingMachine,
		onChangeBookingStartDate,
		onAlignBookings,
		focusViewOnDate,
		onIgnoreAlert,
		onActivateAlert,
		onEditOrder,
		onZoomIn,
		onZoomOut,
		isHidden,
		onToggleVisibility,
	} = props
	const labelsRef = useRef<HTMLDivElement>(null)
	const [ganttRowsParent] = useAutoAnimate()
	const { isDragging } = useDragToScroll<HTMLDivElement>({
		sensitivity: 2,
		scrollRef,
	})
	useScrollSync([scrollRef, labelsRef])
	usePreventOverscrollX()

	const totalHours = useMemo(() => {
		return differenceInHours(endDate, startDate)
	}, [endDate, startDate])

	const xLabelsHeight = 60
	const calendarWidth = totalHours * hourGap
	const topRowPadding = 4

	return (
		<div className="bg-background">
			<div className="fixed bottom-[100px] right-4 z-50 flex items-center gap-1">
				<Button
					variant="outline"
					size="sm"
					onClick={() => focusViewOnDate(new Date())}
				>
					<Home className="h-4 w-4" />
				</Button>
				<Button
					variant="outline"
					size="sm"
					onClick={onZoomIn}
					disabled={onZoomIn === undefined}
				>
					<ZoomIn className="h-4 w-4" />
				</Button>
				<Button
					variant="outline"
					size="sm"
					onClick={onZoomOut}
					disabled={onZoomOut === undefined}
				>
					<ZoomOut className="h-4 w-4" />
				</Button>
				<Button
					variant="outline"
					size="sm"
					disabled={true}
					className="w-[52px] select-none font-mono"
				>
					{zoomLevel}
				</Button>
			</div>
			<div className="relative h-full w-dvw">
				<FadeOut width={rowLabelWidth} />
				<SortableMachineList
					orderedMachines={machines}
					reorder={onReorderMachines}
					rowHeight={rowHeight}
					rowLabelWidth={rowLabelWidth}
					rowLabelMarginLeft={rowLabelMarginLeft}
					onAdjustCalendar={onAdjustMachineCalendar}
					isHidden={isHidden}
					onToggleVisibility={onToggleVisibility}
					style={{ top: xLabelsHeight + topRowPadding }}
				/>
				<div
					ref={labelsRef}
					className="sticky top-0 z-40 overflow-x-hidden shadow-sm"
				>
					<XLabels
						startDate={startDate}
						endDate={endDate}
						hourGap={hourGap}
						step={xLabelsStep}
						height={xLabelsHeight}
						width={calendarWidth}
						zoomLevel={zoomLevel}
					/>
				</div>
				<div
					ref={scrollRef}
					className="relative flex h-full flex-col overflow-x-auto overscroll-x-contain"
					style={{
						cursor: isDragging ? 'grabbing' : 'grab',
					}}
				>
					<div className="relative h-full min-h-[calc(100dvh-239px)] pb-[60px]">
						{/* min-h-[calc(100dvh-239px)] - the 239px is the total height of the navbar, action row, xlabels, and footer */}
						<GridLines
							startDate={startDate}
							endDate={endDate}
							hourGap={hourGap}
							step={xGridLinesStep}
						/>
						<div style={{ height: topRowPadding }} />
						<div ref={ganttRowsParent}>
							{machines.map((machine: TMachine) => {
								const machineHidden = isHidden(machine.id)
								return (
									<GanttRow
										key={machine.id}
										height={rowHeight - 4}
										style={{ width: calendarWidth }}
										className={cn(
											'transition-opacity',
											machineHidden && 'opacity-15',
										)}
									>
										<ClosedPeriods
											availability={machine.availability}
											calendarAdjustments={getCalendarAdjustmentsForMachine({
												calendarAdjustments,
												machineId: machine.id,
											})}
											startDate={startDate}
											endDate={endDate}
											hourGap={hourGap}
											height={rowHeight - 2}
										/>
										{bookings
											.filter(
												booking =>
													booking.machineId === machine.id &&
													isWithinInterval(booking.startDate, {
														start: startDate,
														end: endDate,
													}),
											)
											.sort((a, b) => compareAsc(a.startDate, b.startDate))
											.map(booking => (
												<BookedPeriod
													key={booking.id}
													active={activeBookingId === booking.id}
													muted={
														activeOrderId !== undefined &&
														activeOrderId !== booking.orderId
													}
													onUpdateProgress={onUpdateProgress}
													onMoveBooking={
														booking.status === 'planned' ||
														booking.status === 'in-progress'
															? onMoveBooking
															: undefined
													}
													onSwitchBookingMachine={
														booking.compatibleMachines.length > 1 &&
														booking.status === 'planned'
															? onSwitchBookingMachine
															: undefined
													}
													onChangeBookingStartDate={
														booking.status === 'planned' ||
														booking.status === 'in-progress'
															? onChangeBookingStartDate
															: undefined
													}
													onAlignBookings={onAlignBookings}
													onSelect={onSelectBooking}
													onSelectNext={onSelectNextBooking}
													onSelectPrev={onSelectPrevBooking}
													booking={booking}
													product={products.find(
														product => product.id === booking.productId,
													)}
													order={orders.find(
														order => order.id === booking.orderId,
													)}
													startDate={startDate}
													endDate={endDate}
													hourGap={hourGap}
													height={rowHeight - 14}
													viewRef={scrollRef}
													onEditOrder={() => onEditOrder?.(booking.orderId)}
												/>
											))}
										{getAlertsForMachine({
											alerts,
											machineId: machine.id,
										}).map(alert => (
											<Alert
												key={alert.id}
												alert={alert}
												muted={
													activeOrderId !== undefined &&
													alert.orders.every(o => o.id !== activeOrderId)
												}
												startDate={startDate}
												endDate={endDate}
												hourGap={hourGap}
												height={rowHeight - 14}
												onIgnore={() => onIgnoreAlert(alert)}
												onActivate={() => onActivateAlert(alert)}
											/>
										))}
									</GanttRow>
								)
							})}
						</div>
						<NowLine startDate={startDate} hourGap={hourGap} />
					</div>
				</div>
			</div>
		</div>
	)
}

export { Gantt }
