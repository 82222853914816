import { TMachineBooking } from '@repo/types'
import { Link } from '@tanstack/react-router'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { FileDown, Plus, RotateCcw, Trash2 } from 'lucide-react'
import { toast } from 'sonner'

import { PERSIST_KEY } from '@/app/constants'
import { useAppSelector } from '@/app/hooks'
import { persistor, storage, store } from '@/app/store'
import { AlertCenterIndicator } from '@/features/alerts/components/alert-center-indicator'
import { AddDataDialog } from '@/features/data-management/components/add-data-dialog'
import { ExportDataDialog } from '@/features/data-management/components/export-data-dialog'
import { selectAlertCount } from '@/features/planning/planning-slice'
import { useDialogState } from '@/hooks/use-dialog-state'

import { GenericAlertDialog } from './generic-alert-dialog'
import { HeaderLayout } from './layout'
import { ModeToggle } from './mode-toggle'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from './scrollable-toolbar'
import { Button } from './ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from './ui/dropdown-menu'
import {
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuList,
	navigationMenuTriggerStyle,
} from './ui/navigation-menu'
import { Separator } from './ui/separator'
import logoIcon from '/logo-icon.svg'

function doubleStringifyObject(data: unknown): string {
	return JSON.stringify(
		Object.fromEntries(
			Object.entries(data as Record<string, unknown>).map(([key, value]) => [
				key,
				JSON.stringify(value),
			]),
		),
	)
}

async function processDataset(dataset: unknown): Promise<void> {
	if (dataset && typeof dataset === 'object' && 'data' in dataset) {
		dataset = dataset.data
	}

	if (
		Object.entries(dataset as Record<string, unknown>).every(
			([, value]) => typeof value === 'string',
		)
	) {
		await storage.setItem(PERSIST_KEY, JSON.stringify(dataset))
	} else {
		await storage.setItem(PERSIST_KEY, doubleStringifyObject(dataset))
	}
}

/**
 * Calculates the size in bytes of any serializable data.
 * Returns 0 if the calculation fails or the data is not serializable.
 */
function calculateDataSize(data: unknown): number {
	if (data === undefined || data === null) {
		return 0
	}

	try {
		// Handle primitive types directly
		if (
			typeof data === 'number' ||
			typeof data === 'boolean' ||
			typeof data === 'string'
		) {
			return new Blob([String(data)]).size
		}

		// Handle complex objects and arrays
		const serialized = JSON.stringify(data)
		if (!serialized) {
			return 0
		}

		return new Blob([serialized]).size
	} catch (error) {
		console.warn('Failed to calculate data size:', error)
		return 0
	}
}

export function Header(props: { bookings: TMachineBooking[] }) {
	const { bookings } = props

	const alertCount = useAppSelector(selectAlertCount)

	const deleteDataDialog = useDialogState()
	const exportDataDialog = useDialogState()
	const addDataDialog = useDialogState()

	const currentStateSize = useAppSelector(state => calculateDataSize(state))

	return (
		<>
			<header className="w-full border-b py-2">
				<HeaderLayout>
					<ScrollableToolbar>
						<ScrollableToolbarContentLeft>
							<div className="relative">
								<img
									src={logoIcon}
									width={40}
									height={40}
									className="logo"
									alt="Polly logo"
								/>
								<span className="absolute bottom-0.5 left-[25px] z-10 rounded border border-[#0029F5] bg-white px-0.5 font-mono text-[7px] uppercase text-[#0029F5]">
									Beta
								</span>
							</div>
							<NavigationMenu>
								<NavigationMenuList>
									<NavigationMenuItem>
										<Link
											to="/machines"
											className={navigationMenuTriggerStyle()}
										>
											Machines
										</Link>
									</NavigationMenuItem>
									<NavigationMenuItem>
										<Link
											to="/products"
											className={navigationMenuTriggerStyle()}
										>
											Products
										</Link>
									</NavigationMenuItem>
									<NavigationMenuItem>
										<Link to="/orders" className={navigationMenuTriggerStyle()}>
											Orders
										</Link>
									</NavigationMenuItem>
									<NavigationMenuItem>
										<Link
											to="/planning"
											className={navigationMenuTriggerStyle()}
										>
											Planning
										</Link>
									</NavigationMenuItem>
									{bookings.length > 0 && (
										<>
											<NavigationMenuItem>
												<Link
													to="/progress"
													className={navigationMenuTriggerStyle()}
												>
													Progress
												</Link>
											</NavigationMenuItem>
											<NavigationMenuItem>
												<Link
													to="/staff-demand"
													className={navigationMenuTriggerStyle()}
												>
													Staff Demand
												</Link>
											</NavigationMenuItem>
										</>
									)}
								</NavigationMenuList>
							</NavigationMenu>
						</ScrollableToolbarContentLeft>
						<ScrollableToolbarContentRight>
							<NavigationMenu>
								<NavigationMenuList>
									<NavigationMenuItem>
										<AlertCenterIndicator alertCount={alertCount} />
									</NavigationMenuItem>
								</NavigationMenuList>
							</NavigationMenu>
							<ModeToggle />
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button variant="outline">Settings</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent className="w-24" align="end">
									<DropdownMenuLabel>Manage Data</DropdownMenuLabel>
									<DropdownMenuGroup>
										<DropdownMenuItem onClick={exportDataDialog.open}>
											<FileDown className="mr-2 h-4 w-4" />
											<span>Export Data</span>
										</DropdownMenuItem>
										<DropdownMenuItem onClick={addDataDialog.open}>
											<Plus className="mr-2 h-4 w-4" />
											<span>Add Data</span>
										</DropdownMenuItem>
										<DropdownMenuItem onClick={deleteDataDialog.open}>
											<Trash2 className="mr-2 h-4 w-4" />
											<span>Clear Data</span>
										</DropdownMenuItem>
									</DropdownMenuGroup>
									<Separator />
									<DropdownMenuGroup>
										<DropdownMenuItem onClick={() => location.reload()}>
											<RotateCcw className="mr-2 h-4 w-4" />
											<span>Reload</span>
										</DropdownMenuItem>
									</DropdownMenuGroup>
								</DropdownMenuContent>
							</DropdownMenu>
						</ScrollableToolbarContentRight>
					</ScrollableToolbar>
				</HeaderLayout>
			</header>
			{exportDataDialog.isOpen && (
				<ExportDataDialog
					onClose={exportDataDialog.close}
					fileSizeBytes={currentStateSize}
					onExport={async () => {
						try {
							const serializedStore = JSON.stringify(store.getState()) // Pure JSON, stringified
							const blob = new Blob([serializedStore], {
								type: 'application/json',
							})

							const fileName = `polly-planning_${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.json`

							saveAs(blob, fileName)

							toast.success('Data exported successfully')
						} catch (err) {
							toast.error('An error occurred while exporting data')
							console.error(err)
						}
					}}
				/>
			)}
			{addDataDialog.isOpen && (
				<AddDataDialog
					onClose={addDataDialog.close}
					onAddDemoDataset={async () => {
						try {
							const response = await fetch('/datasets/demo.json')
							const dataset = await response.json()
							await processDataset(dataset)
							addDataDialog.close()
							toast.success(
								'The demo dataset was added successfully. Please refresh the page to see the changes.',
							)
						} catch (err) {
							toast.error('An error occurred while adding the demo dataset')
							console.error(err)
						}
					}}
					onFileUpload={(file: File) => {
						if (file && file.type === 'application/json') {
							const reader = new FileReader()
							reader.onload = async e => {
								try {
									const text = e.target?.result as string
									const dataset = JSON.parse(text)
									await processDataset(dataset)
									addDataDialog.close()
									toast.success(
										'Custom dataset uploaded successfully. Please refresh the page to see the changes.',
									)
								} catch (error) {
									toast.error(
										'An error occurred while processing the uploaded file',
									)
									console.error(error)
								}
							}
							reader.readAsText(file)
						} else {
							toast.error('Please upload a valid JSON file.')
						}
					}}
				/>
			)}
			<GenericAlertDialog
				title="Delete all data"
				description="Are you sure you want to delete all data? This action cannot be undone."
				confirmButtonText="Delete All Data"
				open={deleteDataDialog.isOpen}
				onClose={deleteDataDialog.close}
				onConfirm={() => {
					persistor.purge()
					// TODO: Temporary fix to prevent data loss
					const localStorageItem = localStorage?.getItem(PERSIST_KEY)
					if (localStorageItem) {
						localStorage?.removeItem(PERSIST_KEY)
						localStorage.setItem(`OLD_${PERSIST_KEY}`, localStorageItem)
					}
					deleteDataDialog.close()
					toast.success('All data was deleted')
				}}
			/>
		</>
	)
}
