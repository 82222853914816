import { useMemo, useState } from 'react'

import { TCalendarAdjustment, TMachine } from '@repo/types'

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'

import { CalendarAdjustmentCard } from './calendar-adjustment-card'

function CalendarAdjustmentList(
	props: {
		calendarAdjustments: TCalendarAdjustment[]
		machines: TMachine[]
		onDelete: (id: string) => void
	} & {
		container?: HTMLElement | null
	},
) {
	const { calendarAdjustments, machines, onDelete, container } = props
	const [machineFilter, setMachineFilter] = useState<string>('all')

	const filteredCalendarAdjustments = useMemo(() => {
		return calendarAdjustments.filter(c => {
			if (machineFilter === 'all' || c.affectedMachines.length === 0) {
				return true
			}
			return c.affectedMachines.some(
				affectedMachine => affectedMachine.id === machineFilter,
			)
		})
	}, [calendarAdjustments, machineFilter])

	return (
		<div className="grid gap-4 py-4">
			{calendarAdjustments.length > 0 && (
				<>
					<Select
						value={machineFilter}
						onValueChange={value => setMachineFilter(value)}
					>
						<SelectTrigger className="w-full">
							<div>
								<span className="mr-2 text-muted-foreground">Machine:</span>
								<SelectValue placeholder="Choose Machine" />
							</div>
						</SelectTrigger>
						<SelectContent className="max-h-[44vh]" container={container}>
							<SelectItem value="all">All Machines</SelectItem>
							<Separator />
							{machines.map(machine => (
								<SelectItem key={machine.id} value={machine.id}>
									{machine.name}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					<Separator />
				</>
			)}
			<div className="grid gap-2">
				{filteredCalendarAdjustments.map(c => (
					<CalendarAdjustmentCard
						key={c.id}
						calendarAdjustment={c}
						machines={machines.filter(machine =>
							c.affectedMachines.some(
								affectedMachine => affectedMachine.id === machine.id,
							),
						)}
						onDelete={() => onDelete(c.id)}
						container={container}
					/>
				))}
				{filteredCalendarAdjustments.length === 0 &&
					machineFilter === 'all' && (
						<div className="text-center text-sm text-muted-foreground">
							No active calendar adjustments found.
						</div>
					)}
				{filteredCalendarAdjustments.length === 0 &&
					machineFilter !== 'all' && (
						<div className="text-center text-sm text-muted-foreground">
							No active calendar adjustments match the filter.
						</div>
					)}
			</div>
		</div>
	)
}

export { CalendarAdjustmentList }
