import { useEffect, useMemo, useRef, useState } from 'react'

import { TMachineBooking } from '@repo/types'
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router'
import { format } from 'date-fns'
import { LayoutGrid, X } from 'lucide-react'

import { useAppSelector } from '@/app/hooks'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { AdvancedCombobox } from '@/components/ui/advanced-combobox'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { selectMachines } from '@/features/machines/machines-slice'
import { selectAllOrders } from '@/features/orders/orders-slice'
import {
	calculatePeriod,
	selectCategorizedBookings,
	TBookingFilterCriteria,
} from '@/features/planning/planning-slice'
import { ProgressCard } from '@/features/progress/components/progress-card'
import { useProgressActions } from '@/features/progress/hooks/use-progress-actions'

type ViewOptions = 'list' | 'grid'

type ProgressSearch = {
	view?: ViewOptions
	orderId?: string
	machineId?: string
	changeStartDate?: string
	dateFilter?: Exclude<TBookingFilterCriteria['dateFilter'], null>
}

const Route = createFileRoute('/progress')({
	component: ProgressComponent,
	validateSearch: (search: Record<string, unknown>): ProgressSearch => {
		return {
			view: search.view as ProgressSearch['view'],
			orderId: search.orderId as ProgressSearch['orderId'],
			machineId: search.machineId as ProgressSearch['machineId'],
			changeStartDate:
				search.changeStartDate as ProgressSearch['changeStartDate'],
			dateFilter: (search.dateFilter as ProgressSearch['dateFilter']) ?? '+24h',
		}
	},
})

function ProgressComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })
	const isFiltered =
		search.orderId || search.machineId || search.dateFilter !== '+24h'
	const period = calculatePeriod(search.dateFilter)
	const filterCriteria = useMemo(
		() => ({
			orderId: search.orderId,
			machineId: search.machineId,
			dateFilter: search.dateFilter,
		}),
		[search.orderId, search.machineId, search.dateFilter],
	)

	const { planned, inProgress, completed } = useAppSelector(state =>
		selectCategorizedBookings(state, filterCriteria),
	)
	const machines = useAppSelector(selectMachines)
	const orders = useAppSelector(selectAllOrders)

	const {
		resetBooking,
		startBooking,
		completeBookingSetup,
		startBookingProduction,
		completeBookingProduction,
		completeBooking,
		adjustProgressByPercent,
		adjustProgressByAmount,
		setProgressAmount,
	} = useProgressActions()

	const [activeBookingId, setActiveBookingId] = useState<string | null>(null)
	const activeBookingRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (activeBookingId && activeBookingRef.current) {
			const element = activeBookingRef.current
			const bounding = element.getBoundingClientRect()
			const padding = 72

			const isOutsideViewport =
				bounding.top < 0 || bounding.bottom > window.innerHeight
			if (isOutsideViewport) {
				const offsetTop = bounding.top + window.scrollY - padding

				window.scrollTo({
					top: offsetTop,
					behavior: 'smooth',
				})
			}
			element.classList.add(
				'transition-colors',
				'bg-background/20',
				'border-muted-foreground',
			)
			setTimeout(() => {
				element.classList.remove('bg-background/20', 'border-muted-foreground')
				setTimeout(() => {
					element.classList.remove('transition-colors')
					setActiveBookingId(null)
				}, 150)
			}, 1800)
		}
	}, [activeBookingId])

	function showInPlan(booking: TMachineBooking) {
		navigate({
			to: '/planning',
			search: {
				bookingId: booking.id,
				orderId: booking.orderId,
			},
		})
	}

	return (
		<>
			<PageLayout>
				<ScrollableToolbar>
					<ScrollableToolbarContentLeft>
						<h1 className="text-xl sm:text-2xl">Progress</h1>
					</ScrollableToolbarContentLeft>
					<ScrollableToolbarContentRight>
						{isFiltered && (
							<Button
								variant="ghost"
								onClick={() => {
									navigate({
										search: old => ({
											...old,
											orderId: undefined,
											machineId: undefined,
											dateFilter: undefined,
										}),
									})
								}}
								className="h-8 px-2 lg:px-3"
							>
								Reset
								<X className="ml-2 h-4 w-4" />
							</Button>
						)}
						<AdvancedCombobox
							key={search.orderId}
							label="Order"
							emptyLabel="All Orders"
							initialValue={search.orderId}
							options={orders.map(order => ({
								value: order.id,
								label: order.productionOrderNumber,
							}))}
							searchPlaceholder="Search order..."
							valuePlaceholder="Select an order..."
							classNameButton="w-64"
							onSelect={value => {
								navigate({
									search: old => ({
										...old,
										orderId: value ? value : undefined,
									}),
								})
							}}
						/>
						<AdvancedCombobox
							key={search.machineId}
							label="Machine"
							emptyLabel="All Machines"
							initialValue={search.machineId}
							options={machines.map(machine => ({
								value: machine.id,
								label: machine.name,
							}))}
							searchPlaceholder="Search machine..."
							valuePlaceholder="Select an machine..."
							classNameButton="w-64"
							onSelect={value => {
								navigate({
									search: old => ({
										...old,
										machineId: value ? value : undefined,
									}),
								})
							}}
						/>
						<Select
							key={search.dateFilter}
							value={search.dateFilter}
							onValueChange={value => {
								navigate({
									search: old => ({
										...old,
										dateFilter: value
											? (value as NonNullable<typeof search.dateFilter>)
											: undefined,
									}),
								})
							}}
						>
							<SelectTrigger className="w-[196px] shrink-0">
								<div>
									<span className="mr-2 text-muted-foreground">Time:</span>
									<SelectValue placeholder="Select Time" />
								</div>
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="all">All Time</SelectItem>
								<Separator />
								<SelectItem value="+24h">Next 24 hours</SelectItem>
								<SelectItem value="-24h">Past 24 hours</SelectItem>
								<SelectItem value="+7d">Next 7 days</SelectItem>
								<SelectItem value="-7d">Past 7 days</SelectItem>
							</SelectContent>
						</Select>
						<Tabs value="grid">
							<TabsList aria-label="View options">
								<Tooltip>
									<TooltipTrigger>
										<TabsTrigger
											value="grid"
											aria-label="Switch to grid view"
											asChild
										>
											<Link to="." search={old => ({ ...old, view: 'grid' })}>
												<LayoutGrid className="h-4 w-4" />
											</Link>
										</TabsTrigger>
									</TooltipTrigger>
									<TooltipContent>
										<p>Grid View</p>
									</TooltipContent>
								</Tooltip>
							</TabsList>
						</Tabs>
					</ScrollableToolbarContentRight>
				</ScrollableToolbar>
				<div className="py-8">
					{inProgress.length === 0 &&
						planned.length === 0 &&
						completed.length === 0 && (
							<h2 className="text-md my-2 text-muted-foreground">
								No progress activity found.
							</h2>
						)}
					{inProgress.length > 0 && (
						<div className="mb-10">
							<div className="flex w-full items-center gap-2">
								<h2 className="my-2 text-lg">In Progress</h2>
								<Badge className="justify-center font-mono">
									{inProgress.length}
								</Badge>
								<span className="text-muted-foreground">Showing all</span>
							</div>
							<Separator />
							<div className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
								{inProgress.map(booking => (
									<ProgressCard
										key={booking.id}
										ref={
											booking.id === activeBookingId ? activeBookingRef : null
										}
										booking={booking}
										onResetBooking={() => {
											resetBooking(booking)
											setActiveBookingId(booking.id)
										}}
										onCompleteBookingSetup={() => {
											completeBookingSetup(booking)
											setActiveBookingId(booking.id)
										}}
										onStartBookingProduction={() => {
											startBookingProduction(booking)
											setActiveBookingId(booking.id)
										}}
										onAdjustProgressByPercent={percent => {
											adjustProgressByPercent({ booking, percent })
											setActiveBookingId(booking.id)
										}}
										onAdjustProgressByAmount={amount => {
											adjustProgressByAmount({ booking, amount })
											setActiveBookingId(booking.id)
										}}
										onSetProgressAmount={amount => {
											setProgressAmount({ booking, amount })
											setActiveBookingId(booking.id)
										}}
										onCompleteBookingProduction={() => {
											completeBookingProduction(booking)
											setActiveBookingId(booking.id)
										}}
										onCompleteBooking={() => {
											completeBooking(booking)
											setActiveBookingId(booking.id)
										}}
										onShowInPlan={() => showInPlan(booking)}
									/>
								))}
							</div>
						</div>
					)}
					{planned.length > 0 && (
						<div className="mb-10">
							<div className="flex w-full items-center gap-2">
								<h2 className="my-2 text-lg">Planned</h2>
								<Badge className="justify-center font-mono">
									{planned.length}
								</Badge>
								<span className="text-muted-foreground">
									{period ? (
										<>past &mdash; {format(period.endDate, 'MMM d, yyyy')}</>
									) : (
										<>Showing all</>
									)}
								</span>
							</div>
							<Separator />
							<div className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
								{planned.map(booking => (
									<ProgressCard
										key={booking.id}
										ref={
											booking.id === activeBookingId ? activeBookingRef : null
										}
										booking={booking}
										onStartBooking={() => {
											startBooking(booking)
											setActiveBookingId(booking.id)
										}}
										onShowInPlan={() => showInPlan(booking)}
									/>
								))}
							</div>
						</div>
					)}
					{completed.length > 0 && (
						<div className="mb-10">
							<div className="flex w-full items-center gap-2">
								<h2 className="my-2 text-lg">Completed</h2>
								<Badge className="justify-center font-mono">
									{completed.length}
								</Badge>
								<span className="text-muted-foreground">
									{period ? (
										<>
											{search.dateFilter === '+24h' ||
											search.dateFilter === '+7d'
												? 'now'
												: format(period.startDate, 'MMM d, yyyy')}{' '}
											&mdash;{' '}
											{search.dateFilter === '-24h' ||
											search.dateFilter === '-7d'
												? 'now'
												: format(period.endDate, 'MMM d, yyyy')}
										</>
									) : (
										<>Showing all</>
									)}
								</span>
							</div>
							<Separator />
							<div className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
								{completed.map(booking => (
									<ProgressCard
										key={booking.id}
										ref={
											booking.id === activeBookingId ? activeBookingRef : null
										}
										booking={booking}
										onResetBooking={() => {
											resetBooking(booking)
											setActiveBookingId(booking.id)
										}}
										onShowInPlan={() => showInPlan(booking)}
									/>
								))}
							</div>
						</div>
					)}
				</div>
			</PageLayout>
		</>
	)
}

export { Route }
