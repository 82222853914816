import { useEffect, useState } from 'react'

import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { TMachine } from '@repo/types'
import { Calendar, Eye, EyeOff, GripVertical } from 'lucide-react'

import { Draggable } from '@/components/dnd/draggable'
import { Button } from '@/components/ui/button'
import { GanttRow } from '@/features/gantt/components/gantt'
import { useTouchDeviceDetector } from '@/hooks/use-touch-device-detector'
import { cn } from '@/lib/utils'

function usePersistentOrderedList<T extends { id: string }>(
	list: T[],
	key: string,
) {
	const [orderedList, setOrderedList] = useState<(T & { hidden?: boolean })[]>(
		() => {
			const storedList = localStorage.getItem(key)
			return storedList
				? JSON.parse(storedList)
				: list.map(item => ({ ...item }))
		},
	)

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(orderedList))
	}, [orderedList, key])

	useEffect(() => {
		setOrderedList(prevList => {
			const orderMap = new Map<string, number>()
			prevList.forEach((item, index) => {
				orderMap.set(item.id, prevList.length - index)
			})
			const fallbackOrderMap = new Map<string, number>()
			list.forEach((item, index) => {
				fallbackOrderMap.set(item.id, -index)
			})
			const newList = [...list].map(item => {
				const existingItem = prevList.find(i => i.id === item.id)
				return {
					...item,
					hidden: existingItem?.hidden,
				}
			})
			newList.sort((a, b) => {
				const valueA = orderMap.get(a.id) ?? fallbackOrderMap.get(a.id) ?? 0
				const valueB = orderMap.get(b.id) ?? fallbackOrderMap.get(b.id) ?? 0
				return valueB - valueA
			})
			return newList
		})
	}, [list])

	function reorder(args: { oldIndex: number; newIndex: number }) {
		const { oldIndex, newIndex } = args
		setOrderedList(prevList => {
			const newList = [...prevList]
			const [removed] = newList.splice(oldIndex, 1)
			newList.splice(newIndex, 0, removed)
			return newList
		})
	}

	function toggleVisibility(id: string) {
		setOrderedList(currentList => {
			const index = currentList.findIndex(item => item.id === id)
			if (index === -1) return currentList

			const newList = [...currentList]
			const item = { ...newList[index] }
			const isHiding = !item.hidden

			// Remove the item from its current position
			newList.splice(index, 1)

			if (isHiding) {
				// When hiding: move to top of hidden section
				item.hidden = true
				const firstHiddenIndex = newList.findIndex(i => i.hidden)
				if (firstHiddenIndex === -1) {
					// If no hidden items, add to end
					newList.push(item)
				} else {
					// Insert at the top of hidden section
					newList.splice(firstHiddenIndex, 0, item)
				}
			} else {
				// When showing: move above first hidden item
				item.hidden = false
				const firstHiddenIndex = newList.findIndex(i => i.hidden)
				if (firstHiddenIndex === -1) {
					// If no hidden items, add to end
					newList.push(item)
				} else {
					// Insert before first hidden item
					newList.splice(firstHiddenIndex, 0, item)
				}
			}

			return newList
		})
	}

	function isHidden(id: string) {
		return orderedList.find(item => item.id === id)?.hidden ?? false
	}

	return { orderedList, reorder, toggleVisibility, isHidden }
}

function SortableMachineList(
	props: {
		orderedMachines: TMachine[]
		reorder: (args: { oldIndex: number; newIndex: number }) => void
		rowHeight: number
		rowLabelWidth: number
		rowLabelMarginLeft: number
		onAdjustCalendar: (machineId: string) => void
		isHidden: (machineId: string) => boolean
		onToggleVisibility: (machineId: string) => void
	} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style'>,
) {
	const {
		orderedMachines,
		reorder,
		rowHeight,
		rowLabelWidth,
		rowLabelMarginLeft,
		onAdjustCalendar,
		isHidden,
		onToggleVisibility,
		style,
	} = props
	const [parent, enableAnimations] = useAutoAnimate()
	const { isTouchDevice } = useTouchDeviceDetector()

	function handleDragStart() {
		enableAnimations(false)
	}

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event

		if (active.id !== over?.id) {
			const oldIndex = orderedMachines.findIndex(m => m.id === active.id)
			const newIndex = orderedMachines.findIndex(m => m.id === over?.id)
			if (oldIndex === -1 || newIndex === -1) return
			reorder({ newIndex, oldIndex })
		}
		setTimeout(() => enableAnimations(true), 0)
	}

	return (
		<DndContext
			sensors={useSensors(
				useSensor(isTouchDevice ? TouchSensor : PointerSensor, {
					activationConstraint: isTouchDevice
						? { delay: 250, tolerance: 5 }
						: { distance: 10 },
				}),
				useSensor(KeyboardSensor, {
					coordinateGetter: sortableKeyboardCoordinates,
				}),
			)}
			modifiers={[restrictToParentElement]}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
		>
			<SortableContext
				strategy={verticalListSortingStrategy}
				items={orderedMachines.map(m => m.id)}
			>
				<div
					ref={parent}
					className="absolute left-0 top-0 z-40"
					style={{
						...style,
						marginLeft: rowLabelMarginLeft,
						width: rowLabelWidth,
					}}
				>
					{orderedMachines.map(machine => {
						const machineHidden = isHidden(machine.id)
						return (
							<Draggable
								key={machine.id}
								id={machine.id}
								className="rounded-md"
							>
								<GanttRow
									key={machine.id}
									className={cn(
										'group rounded-md border bg-background text-sm shadow-sm transition-opacity',
										machineHidden && 'opacity-30 hover:opacity-60',
									)}
									height={rowHeight - 4}
								>
									<GripVertical className="mr-2 h-4 min-h-4 w-4 min-w-4 cursor-move text-muted-foreground" />
									<span className="truncate">{machine.name}</span>
									<div className="ml-auto flex gap-1 pl-1">
										<Button
											title={machineHidden ? 'Show Machine' : 'Hide Machine'}
											variant="outline"
											className="hidden h-auto bg-background p-1 leading-none text-foreground shadow-md group-hover:block"
											onClick={() => onToggleVisibility(machine.id)}
										>
											{machineHidden ? (
												<EyeOff className="h-4 w-4" />
											) : (
												<Eye className="h-4 w-4" />
											)}
										</Button>
										<Button
											title="Adjust Calendar"
											variant="outline"
											className="hidden h-auto bg-background p-1 leading-none text-foreground shadow-md group-hover:block"
											onClick={() => onAdjustCalendar(machine.id)}
										>
											<Calendar className="h-4 w-4" />
										</Button>
									</div>
								</GanttRow>
							</Draggable>
						)
					})}
				</div>
			</SortableContext>
		</DndContext>
	)
}

export { SortableMachineList, usePersistentOrderedList }
