import { z } from 'zod'

export const intervalSchema = z.enum([
	'15min',
	'30min',
	'1h',
	'4h',
	'8h',
	'1d',
	'7d',
])
export const chartTypeSchema = z.enum(['stacked', 'grouped'])
export const aggregationTypeSchema = z.enum(['min', 'avg', 'max'])

export type IntervalOption = z.infer<typeof intervalSchema>
export type ChartTypeOption = z.infer<typeof chartTypeSchema>
export type AggregationTypeOption = z.infer<typeof aggregationTypeSchema>
