import { CheckIcon, CopyIcon } from 'lucide-react'
import { toast } from 'sonner'

import { Badge, BadgeProps } from '@/components/ui/badge'
import { useCopyToClipboard } from '@/lib/hooks/use-copy-to-clipboard'
import { cn } from '@/lib/utils'

export function ClickToCopyBadge(
	props: {
		value: string
		displayValue?: string
		variant?: BadgeProps['variant']
	} & React.HTMLAttributes<HTMLDivElement>,
) {
	const { value, displayValue, variant = 'default', className, ...rest } = props
	const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })

	const handleCopy = () => {
		copyToClipboard(value, {
			onSuccess: () => {
				toast.success('Copied to clipboard!', {
					duration: 2000,
				})
			},
			onError: () => {
				toast.error('Failed to copy. Please try again.', {
					duration: 2000,
				})
			},
		})
	}

	return (
		<Badge
			variant={variant}
			onClick={handleCopy}
			className={cn('inline-flex cursor-pointer items-center', className)}
			{...rest}
		>
			<span className="truncate">{displayValue ?? value}</span>
			{isCopied ? (
				<CheckIcon className="ml-2 h-3.5 w-3.5 shrink-0" />
			) : (
				<CopyIcon className="ml-2 h-3.5 w-3.5 shrink-0" />
			)}
		</Badge>
	)
}
