import { useMemo, useState } from 'react'

import { TOrder, TPendingOrder, TPlannedMachineBooking } from '@repo/types'
import { Table } from '@tanstack/react-table'
import {
	ArrowLeftFromLine,
	ArrowRightFromLine,
	Calendar,
	ChevronDown,
	Eraser,
	Trash2,
} from 'lucide-react'
import { toast } from 'sonner'

import { useAppDispatch } from '@/app/hooks'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { cascadeDeleteOrders } from '@/features/orders/orders-slice'
import { formatNumericString } from '@/utils/format-numeric-string'

interface DataTableSelectedRowsActionsProps<TData> {
	table: Table<TData>
	resetOrderPlan: (orderId: string) => Promise<void>
	planOrder: (args: {
		order: TPendingOrder
		direction: 'forward' | 'backward'
	}) => Promise<TPlannedMachineBooking[] | null>
}

export function DataTableSelectedRowActions<TData>({
	table,
	resetOrderPlan,
	planOrder,
}: DataTableSelectedRowsActionsProps<TData>) {
	const dispatch = useAppDispatch()
	const [showUnscheduleAllDialog, setShowUnscheduleAllDialog] = useState(false)
	const [showDeleteAllDialog, setShowDeleteAllDialog] = useState(false)
	const [isUnscheduling, setIsUnscheduling] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)
	const [isPlanning, setIsPlanning] = useState(false)
	const selectedRows = table.getFilteredSelectedRowModel().rows
	const hasSelectedRows = selectedRows.length > 0

	const { plannedOrders, pendingOrders } = useMemo(() => {
		const planned: TOrder[] = []
		const pending: TPendingOrder[] = []

		selectedRows.forEach(row => {
			const order = row.original as TOrder
			const status = order.status

			if (['planned', 'in-progress'].includes(status)) {
				planned.push(order)
			}

			if (status === 'pending') {
				pending.push(order)
			}
		})

		return {
			plannedOrders: planned,
			pendingOrders: pending,
		}
	}, [selectedRows])

	const hasPlannedOrders = plannedOrders.length > 0
	const hasPendingOrders = pendingOrders.length > 0

	if (!hasSelectedRows) return null

	async function handlePlanOrders(direction: 'forward' | 'backward') {
		setIsPlanning(true)
		try {
			for (const order of pendingOrders) {
				const result = await planOrder({
					order,
					direction,
				})

				// If planning failed for this order, stop the sequence
				if (!result) {
					toast.error(`Failed to plan order #${order.id}`)
					break
				}
			}
		} finally {
			toast.success(
				`Planned ${formatNumericString(pendingOrders.length)} orders`,
			)
			setIsPlanning(false)
		}
	}

	async function handleUnscheduleConfirm() {
		setShowUnscheduleAllDialog(false)
		setIsUnscheduling(true)
		try {
			for (const order of plannedOrders) {
				await resetOrderPlan(order.id)
			}
		} finally {
			toast.success(
				`Unscheduled ${formatNumericString(plannedOrders.length)} orders`,
			)
			setIsUnscheduling(false)
		}
	}

	async function handleDeleteConfirm() {
		setShowDeleteAllDialog(false)
		setIsDeleting(true)
		try {
			const orderIds = selectedRows.map(row => (row.original as TOrder).id)
			await dispatch(cascadeDeleteOrders(orderIds)).unwrap()
		} finally {
			setIsDeleting(false)
		}
	}

	return (
		<>
			<div className="flex gap-2">
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button
							className="h-8"
							variant="outline"
							disabled={!hasPendingOrders || isPlanning}
						>
							<Calendar className="mr-2 h-4 w-4" />
							<span>{isPlanning ? 'Planning...' : 'Plan'}</span>
							<Badge variant="outline" className="ml-2">
								{pendingOrders.length}
							</Badge>
							<ChevronDown className="ml-2 h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end">
						<DropdownMenuItem onClick={() => handlePlanOrders('forward')}>
							<ArrowRightFromLine className="mr-2 h-4 w-4" />
							<span>Plan Forward</span>
						</DropdownMenuItem>
						<DropdownMenuItem onClick={() => handlePlanOrders('backward')}>
							<ArrowLeftFromLine className="mr-2 h-4 w-4" />
							<span>Plan Backward</span>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>

				<Button
					className="h-8"
					variant="outline"
					disabled={!hasPlannedOrders || isUnscheduling}
					onClick={() => setShowUnscheduleAllDialog(true)}
				>
					<Eraser className="mr-2 h-4 w-4" />
					<span>{isUnscheduling ? 'Unscheduling...' : 'Unschedule'}</span>
					<Badge variant="outline" className="ml-2">
						{plannedOrders.length}
					</Badge>
				</Button>

				<Button
					className="h-8"
					variant="outline"
					disabled={isDeleting}
					onClick={() => setShowDeleteAllDialog(true)}
				>
					<Trash2 className="mr-2 h-4 w-4" />
					<span>{isDeleting ? 'Deleting...' : 'Delete'}</span>
					<Badge variant="outline" className="ml-2">
						{selectedRows.length}
					</Badge>
				</Button>
			</div>

			{showUnscheduleAllDialog && (
				<GenericAlertDialog
					title={`Unschedule ${formatNumericString(plannedOrders.length)} orders`}
					description="Are you sure you want to unschedule all selected orders? This action cannot be undone, but you can always plan them again."
					confirmButtonText="Unschedule All"
					destructive
					onClose={() => setShowUnscheduleAllDialog(false)}
					onConfirm={handleUnscheduleConfirm}
				/>
			)}

			{showDeleteAllDialog && (
				<GenericAlertDialog
					title={`Delete ${formatNumericString(selectedRows.length)} orders`}
					description="Are you sure you want to delete all selected orders? This action cannot be undone."
					confirmButtonText="Delete All"
					destructive
					onClose={() => setShowDeleteAllDialog(false)}
					onConfirm={handleDeleteConfirm}
				/>
			)}
		</>
	)
}
