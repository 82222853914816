import { useState } from 'react'

import { Check, ChevronsUpDown } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'

import { Separator } from './separator'

type Option = {
	value: string
	label: string
}

function AdvancedCombobox(
	props: {
		label?: string
		emptyLabel?: string
		options: Option[]
		onSelect: (value?: string) => void
		searchPlaceholder: string
		valuePlaceholder: string
		initialValue?: string
		canDeselect?: boolean
		disabled?: boolean
		error?: boolean
		allowCreation?: boolean
		classNameButton?: string
		classNameContent?: string
	} & {
		container?: HTMLElement | null
	},
) {
	const {
		label,
		emptyLabel,
		options,
		onSelect,
		searchPlaceholder,
		valuePlaceholder,
		initialValue = '',
		disabled = false,
		error = false,
		classNameButton = '',
		classNameContent = '',
		container,
	} = props
	const [open, setOpen] = useState(false)
	const [value, setValue] = useState<string>(initialValue)
	const [search, setSearch] = useState('')

	const selectedLabel = options.find(option => option.value === value)?.label

	return (
		<Popover open={open} onOpenChange={setOpen} modal>
			<PopoverTrigger asChild>
				<Button
					disabled={disabled}
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className={cn(
						'w-[200px] justify-between',
						error && 'border-destructive',
						classNameButton,
					)}
				>
					<div>
						{label && (
							<span className="mr-2 text-muted-foreground">{label}:</span>
						)}
						<span className="truncate">
							{selectedLabel ??
								(value === '' && emptyLabel ? emptyLabel : valuePlaceholder)}
						</span>
					</div>
					{!disabled && (
						<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className={cn(
					'max-h-[--radix-popover-content-available-height] w-[--radix-popover-trigger-width] p-0',
					classNameContent,
				)}
				align="start"
				container={container}
			>
				<Command>
					<CommandInput
						placeholder={searchPlaceholder}
						value={search}
						onValueChange={setSearch}
					/>
					<CommandList>
						<CommandEmpty>No results found.</CommandEmpty>
						{search === '' && emptyLabel && (
							<>
								<CommandGroup>
									<CommandItem
										value="@"
										onSelect={() => {
											setSearch('')
											setValue('')
											onSelect('')
											setOpen(false)
										}}
									>
										<Check
											className={cn(
												'mr-2 h-4 w-4',
												value === '' ? 'opacity-100' : 'opacity-0',
											)}
										/>
										{emptyLabel}
									</CommandItem>
								</CommandGroup>
								<Separator />
							</>
						)}
						<CommandGroup>
							{options.map(option => (
								<CommandItem
									key={option.value}
									value={option.label}
									onSelect={() => {
										setSearch('')
										setValue(option.value)
										onSelect(option.value)
										setOpen(false)
									}}
								>
									<Check
										className={cn(
											'mr-2 h-4 w-4',
											value === option.value ? 'opacity-100' : 'opacity-0',
										)}
									/>
									{option.label}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}

export { AdvancedCombobox }
