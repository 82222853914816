import { TOrder } from '@repo/types'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import {
	createCustomer,
	selectCustomers,
} from '@/features/customers/customers-slice'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { OrderForm } from '@/features/orders/forms/order-form'
import {
	editOrder,
	selectProductionOrderNumbers,
	updateOrderQuantity,
} from '@/features/orders/orders-slice'
import { validatePlan } from '@/features/planning/planning-slice'
import { selectProducts } from '@/features/products/products-slice'

export function EditOrderDialog(props: { order: TOrder; onClose: () => void }) {
	const { order, onClose } = props
	const dispatch = useAppDispatch()
	const customers = useAppSelector(selectCustomers)
	const products = useAppSelector(selectProducts)
	const productionOrderNumbers = useAppSelector(selectProductionOrderNumbers)

	const { ConfirmExitAlertDialog, formDialogProps, formProps } =
		useConfirmExitFormDialog<Omit<TOrder, 'id' | 'status'>>({
			onSubmit: changes => {
				// If quantity changed and order is planned, use updateOrderQuantity
				if (changes.quantity !== order.quantity && order.status === 'planned') {
					dispatch(
						updateOrderQuantity({
							id: order.id,
							quantity: changes.quantity,
						}),
					)
					// Update other fields separately, excluding quantity
					const {
						productionOrderNumber,
						salesOrderNumber,
						customerName,
						product,
						dueDate,
						buffer,
					} = changes
					const otherChanges = {
						productionOrderNumber,
						salesOrderNumber,
						customerName,
						product,
						dueDate,
						buffer,
					}
					if (Object.keys(otherChanges).length > 0) {
						dispatch(editOrder({ id: order.id, changes: otherChanges }))
					}
				} else {
					// For non-planned orders or no quantity change, use regular editOrder
					dispatch(editOrder({ id: order.id, changes }))
				}
				dispatch(createCustomer({ name: changes.customerName }))
				dispatch(validatePlan())
				toast.success(`Order "${changes.productionOrderNumber}" updated`)
			},
			onClose,
		})

	return (
		<>
			<ConfirmExitAlertDialog />
			<GenericDialog title="Edit order" {...formDialogProps}>
				<OrderForm
					submitButtons={<Button type="submit">Save Order</Button>}
					initialValues={order}
					invalidProductionOrderNumbers={productionOrderNumbers.filter(
						productionOrderNumber =>
							productionOrderNumber !== order.productionOrderNumber,
					)}
					customers={customers}
					products={products}
					{...formProps}
				/>
			</GenericDialog>
		</>
	)
}
