import { TOrder, TPendingOrder, TProduct } from '@repo/types'
import { isAfter, parse, startOfDay } from 'date-fns'
import Papa from 'papaparse'
import { z } from 'zod'

import { parseFlexibleNumber } from '@/utils/parse-flexible-number'

const parseFlexibleDate = (val: string, errorMessage: string): string => {
	// Handle DD-MM-YYYY and DD.MM.YYYY formats
	const ddmmyyyyRegex = /^(\d{2})[-.](\d{2})[-.](\d{4})$/
	const ddmmyyyyMatch = val.match(ddmmyyyyRegex)
	if (ddmmyyyyMatch) {
		const [, day, month, year] = ddmmyyyyMatch
		const date = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date())
		if (!isNaN(date.getTime())) {
			return date.toISOString()
		}
	}

	// Handle ISO 8601 datetime format
	if (val.includes('T')) {
		const date = new Date(val)
		if (!isNaN(date.getTime())) {
			return date.toISOString()
		}
	}

	// Handle YYYY-MM-DD format
	const yyyymmddRegex = /^\d{4}-\d{2}-\d{2}$/
	if (yyyymmddRegex.test(val)) {
		const date = parse(val, 'yyyy-MM-dd', new Date())
		if (!isNaN(date.getTime())) {
			return date.toISOString()
		}
	}

	throw new Error(errorMessage)
}

const orderImportRowSchema = z
	.object({
		'Production Order Number': z
			.string()
			.trim()
			.min(1, 'Production Order Number is required'),
		'Sales Order Number': z
			.string()
			.trim()
			.min(1, 'Sales Order Number is required'),
		'Customer Name': z.string().trim().min(1, 'Customer Name is required'),
		'Product Number': z.string().trim().min(1, 'Product Number is required'),
		'Product Name': z.string().trim().min(1, 'Product Name is required'),
		Quantity: z
			.string()
			.transform(value => parseFlexibleNumber(value, 'Invalid quantity format'))
			.refine(value => value >= 0, {
				message: 'Quantity must be ≥ 0',
			}),
		'Earliest Start Date': z
			.string()
			.trim()
			.transform(val => {
				if (!val) {
					// Set default earliest start date to today
					return startOfDay(new Date()).toISOString()
				}
				return parseFlexibleDate(
					val,
					'Invalid Earliest Start Date: Use YYYY-MM-DD, DD-MM-YYYY, DD.MM.YYYY, or ISO 8601 format',
				)
			}),
		'Due Date': z
			.string()
			.trim()
			.transform(val =>
				parseFlexibleDate(
					val,
					'Invalid Due Date: Use YYYY-MM-DD, DD-MM-YYYY, DD.MM.YYYY, or ISO 8601 format',
				),
			),
		'Buffer Hours': z
			.string()
			.default('0')
			.transform(value =>
				parseFlexibleNumber(value, 'Invalid buffer hours format'),
			)
			.refine(value => value >= 0, {
				message: 'Buffer hours must be ≥ 0',
			})
			.refine(value => value <= 1000, {
				message: 'Buffer hours must be ≤ 1000',
			}),
	})
	.refine(
		data => {
			const startDate = data['Earliest Start Date']
				? new Date(data['Earliest Start Date'])
				: new Date()
			return !isAfter(startDate, new Date(data['Due Date']))
		},
		{
			message: 'Earliest start date must be before or equal to the due date',
		},
	)

export type OrderImportRow = z.infer<typeof orderImportRowSchema>

export type OrderImportAction = {
	type: 'create' | 'update' | 'skip'
	order: Omit<TPendingOrder, 'id' | 'status'> & {
		id?: string
		existingOrder?: TOrder
		duplicateInFile?: {
			row: number
			laterRow: number
		}
	}
}

export type OrderImportResult = {
	success: boolean
	actions: OrderImportAction[]
	errors: Array<{
		row: number
		message: string
		data?: OrderImportRow
	}>
	summary: {
		total: number
		successful: number
		failed: number
		skipped: number
	}
}

export type OrderImportOptions = {
	onDuplicate: 'skip' | 'overwrite'
	products: TProduct[]
	existingOrders: TOrder[]
	previewOnly?: boolean
}

const validateAndParseRow = (
	row: OrderImportRow,
	products: TProduct[],
): Omit<TPendingOrder, 'id' | 'status'> => {
	// First validate the row structure with Zod
	const parsedRow = orderImportRowSchema.parse(row)

	// Additional validation for product existence
	const product = products.find(
		p =>
			p.productNumber === parsedRow['Product Number'] &&
			p.name === parsedRow['Product Name'],
	)

	if (!product) {
		throw new Error(
			`Product not found. Please verify both product number #${parsedRow['Product Number']} and name "${parsedRow['Product Name']}" are correct`,
		)
	}

	// Create the order object
	return {
		productionOrderNumber: parsedRow['Production Order Number'],
		salesOrderNumber: parsedRow['Sales Order Number'],
		customerName: parsedRow['Customer Name'],
		product: { id: product.id },
		quantity: parsedRow['Quantity'],
		earliestStartDate: parsedRow['Earliest Start Date'],
		dueDate: parsedRow['Due Date'],
		buffer: {
			quantity: parsedRow['Buffer Hours'],
			unit: 'hours',
		},
	}
}

export async function importOrders(
	file: File,
	options: OrderImportOptions,
): Promise<OrderImportResult> {
	const result: OrderImportResult = {
		success: false,
		actions: [],
		errors: [],
		summary: {
			total: 0,
			successful: 0,
			failed: 0,
			skipped: 0,
		},
	}

	return new Promise(resolve => {
		Papa.parse<OrderImportRow>(file, {
			header: true,
			skipEmptyLines: true,
			complete: parseResults => {
				result.summary.total = parseResults.data.length

				// Track orders by production order number
				const orderMap = new Map<
					string,
					{
						row: number
						action: OrderImportAction
					}
				>()

				// Process each row
				parseResults.data.forEach((row, index) => {
					try {
						const order = validateAndParseRow(row, options.products)

						// Check for duplicates in existing orders
						const existingOrder = options.existingOrders.find(
							o => o.productionOrderNumber === order.productionOrderNumber,
						)

						let action: OrderImportAction

						if (existingOrder) {
							// Skip if order is already completed, regardless of user settings
							if (existingOrder.status === 'completed') {
								action = {
									type: 'skip',
									order: {
										...order,
										id: existingOrder.id,
										existingOrder,
									},
								}
								result.summary.skipped++
							} else if (options.onDuplicate === 'skip') {
								action = {
									type: 'skip',
									order: {
										...order,
										id: existingOrder.id,
										existingOrder,
									},
								}
								result.summary.skipped++
							} else {
								action = {
									type: 'update',
									order: { ...order, id: existingOrder.id },
								}
							}
						} else {
							action = {
								type: 'create',
								order,
							}
						}

						// Check for duplicates within the CSV
						const existingEntry = orderMap.get(order.productionOrderNumber)
						if (existingEntry) {
							result.actions.push({
								type: 'skip',
								order: {
									...existingEntry.action.order,
									duplicateInFile: {
										row: existingEntry.row,
										laterRow: index + 1,
									},
								},
							})
							result.summary.skipped++
						}

						// Store/update the entry in our map (last occurrence wins)
						orderMap.set(order.productionOrderNumber, {
							row: index + 1,
							action,
						})

						result.summary.successful++
					} catch (error) {
						result.errors.push({
							row: index + 1,
							message:
								error instanceof z.ZodError
									? error.errors[0].message
									: (error as Error).message,
							data: row,
						})
						result.summary.failed++
					}
				})

				// Add the final actions from our map
				result.actions.push(...Array.from(orderMap.values()).map(e => e.action))

				// Consider import successful if there are any valid actions
				result.success = result.actions.length > 0
				resolve(result)
			},
			error: error => {
				result.errors.push({
					row: 0,
					message: error.message,
				})
				resolve(result)
			},
		})
	})
}

export function generateOrderImportTemplate(): string {
	const headers = [
		'Production Order Number',
		'Sales Order Number',
		'Customer Name',
		'Product Number',
		'Product Name',
		'Quantity',
		'Buffer Hours',
		'Earliest Start Date',
		'Due Date',
	]

	const today = new Date()
	const dueDate = new Date()
	dueDate.setMonth(dueDate.getMonth() + 1)

	const sampleData = [
		'"P1001"',
		'"1001"',
		'"Customer"',
		'"12345678"',
		'"Product"',
		10000000,
		0,
		`"${today.toISOString().split('T')[0]}"`,
		`"${dueDate.toISOString().split('T')[0]}"`,
	]

	return [headers.join(','), sampleData.join(',')].join('\n')
}
