import { format, isSameDay } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'
import { DateRange } from 'react-day-picker'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'

interface DateRangePickerProps {
	className?: string
	selected?: DateRange
	onSelect?: (date: DateRange | undefined) => void
	align?: 'start' | 'center' | 'end'
}

export function DateRangePicker({
	className,
	selected,
	onSelect,
	align = 'start',
}: DateRangePickerProps) {
	return (
		<div className={cn('grid gap-2', className)}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant="outline"
						className={cn(
							'w-[250px] justify-start text-left font-normal',
							!selected && 'text-muted-foreground',
						)}
					>
						<CalendarIcon className="mr-2 h-4 w-4" />
						{selected?.from ? (
							selected.to && !isSameDay(selected.from, selected.to) ? (
								<>
									{format(selected.from, 'LLL dd, y')} -{' '}
									{format(selected.to, 'LLL dd, y')}
								</>
							) : (
								format(selected.from, 'LLL dd, y')
							)
						) : (
							<span>Pick a date</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align={align}>
					<Calendar
						initialFocus
						mode="range"
						defaultMonth={selected?.from}
						selected={selected}
						onSelect={onSelect}
						numberOfMonths={2}
						disabled={date =>
							date >
								new Date(
									new Date().setFullYear(new Date().getFullYear() + 1),
								) ||
							date <
								new Date(new Date().setFullYear(new Date().getFullYear() - 1))
						}
					/>
				</PopoverContent>
			</Popover>
		</div>
	)
}
