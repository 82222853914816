import {
	TCompletedMachineBooking,
	TInProgressMachineBooking,
	TPlannedMachineBooking,
} from '@repo/types'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import {
	advanceOrderToCompleted,
	advanceOrderToInProgress,
	selectOrderEntities,
} from '@/features/orders/orders-slice'
import { useOrderPlanner } from '@/features/planning/hooks/use-order-planner'
import {
	completeBooking as completeBookingAction,
	completeBookingSetup as completeBookingSetupAction,
	resetProgress as resetProgressAction,
	selectCompletedBookings,
	startBooking as startBookingAction,
	startBookingProduction as startBookingProductionAction,
	startBookingTeardown as startBookingTeardownAction,
	updateProgress as updateProgressAction,
	validatePlan,
} from '@/features/planning/planning-slice'

export function useProgressActions() {
	const dispatch = useAppDispatch()
	const allCompletedBookings = useAppSelector(selectCompletedBookings)
	const orderEntities = useAppSelector(selectOrderEntities)
	const { moveBooking } = useOrderPlanner()

	const resetBooking = (
		booking: TInProgressMachineBooking | TCompletedMachineBooking,
	) => {
		dispatch(resetProgressAction(booking.id))
	}

	function startBooking(booking: TPlannedMachineBooking) {
		moveBooking({ booking, desiredStartDate: new Date() })
		dispatch(
			startBookingAction({
				id: booking.id,
				startDate: new Date().toISOString(),
			}),
		)
		const order = orderEntities[booking.orderId]
		if (order?.status === 'planned') {
			dispatch(advanceOrderToInProgress(order.id))
		}
	}

	function completeBookingSetup(booking: TInProgressMachineBooking) {
		dispatch(
			completeBookingSetupAction({
				id: booking.id,
				endDate: new Date().toISOString(),
			}),
		)
	}

	function startBookingProduction(booking: TInProgressMachineBooking) {
		dispatch(
			startBookingProductionAction({
				id: booking.id,
				startDate: new Date().toISOString(),
			}),
		)
	}

	function completeBookingProduction(booking: TInProgressMachineBooking) {
		if (booking.effectiveTimeMinutes.after > 0) {
			dispatch(
				startBookingTeardownAction({
					id: booking.id,
					startDate: new Date().toISOString(),
				}),
			)
		} else {
			completeBooking(booking)
		}
	}

	function completeBooking(booking: TInProgressMachineBooking) {
		dispatch(
			completeBookingAction({
				id: booking.id,
				endDate: new Date().toISOString(),
			}),
		)
		dispatch(validatePlan())
		const order = orderEntities[booking.orderId]
		if (order?.status === 'in-progress') {
			const bookingIds = order.bookingIds
			const orderIsComplete =
				allCompletedBookings.filter(
					b =>
						b.id !== booking.id &&
						bookingIds.includes(b.id) &&
						b.status === 'completed',
				).length ===
				bookingIds.length - 1
			if (orderIsComplete) {
				dispatch(
					advanceOrderToCompleted({
						id: order.id,
						completionDate: new Date().toISOString(),
					}),
				)
			}
		}
		toast.success('Operation completed successfully')
	}

	function adjustProgressByPercent(args: {
		booking: TInProgressMachineBooking
		percent: number
	}) {
		const { booking, percent } = args
		const order = orderEntities[booking.orderId]
		if (order && 'planningParameters' in order) {
			const plannedQuantity = order.planningParameters.quantity
			const updatedProgressPercentage = Math.max(
				0,
				Math.min(booking.progress.during.percentage + percent, 100),
			)

			dispatch(
				updateProgressAction({
					id: booking.id,
					percentage: updatedProgressPercentage,
					quantity: Math.round(
						(updatedProgressPercentage / 100) * plannedQuantity,
					),
				}),
			)
		}
	}

	function adjustProgressByAmount(args: {
		booking: TInProgressMachineBooking
		amount: number
	}) {
		const { booking, amount } = args
		const order = orderEntities[booking.orderId]
		if (order && 'planningParameters' in order) {
			const plannedQuantity = order.planningParameters.quantity
			const currentQuantity = booking.progress.during.quantity
			const updatedQuantity = Math.max(
				0,
				Math.min(currentQuantity + amount, plannedQuantity),
			)
			const updatedProgressPercentage = Number(
				((updatedQuantity / plannedQuantity) * 100).toFixed(2),
			)

			dispatch(
				updateProgressAction({
					id: booking.id,
					percentage: updatedProgressPercentage,
					quantity: updatedQuantity,
				}),
			)
		}
	}

	function setProgressAmount(args: {
		booking: TInProgressMachineBooking
		amount: number
	}) {
		const { booking, amount } = args
		const order = orderEntities[booking.orderId]
		if (order && 'planningParameters' in order) {
			const plannedQuantity = order.planningParameters.quantity
			const updatedQuantity = Math.max(0, Math.min(amount, plannedQuantity))
			const updatedProgressPercentage = Number(
				((updatedQuantity / plannedQuantity) * 100).toFixed(2),
			)

			dispatch(
				updateProgressAction({
					id: booking.id,
					percentage: Math.min(updatedProgressPercentage, 100),
					quantity: updatedQuantity,
				}),
			)
		}
	}

	return {
		resetBooking,
		startBooking,
		completeBookingSetup,
		startBookingProduction,
		completeBookingProduction,
		completeBooking,
		adjustProgressByPercent,
		adjustProgressByAmount,
		setProgressAmount,
	}
}
